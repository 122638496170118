import { Badge } from "antd";
import { SvgAWBFilled, SvgConfirmOrderFilled, SvgCoverageFilled, SvgExclamationMarkFilled, SvgFAQFilled, SvgFeedbackFilled, SvgHistoryFilled, SvgInvoiceFilled, SvgLearningFilled, SvgLocationFilled, SvgLoyaltyFilled, SvgNearby, SvgNotificationBellFilled, SvgOrderDollyFilled, SvgPickupFilled, SvgPrivacyPolicyFilled, SvgROM, SvgRTSFilled, SvgStoreFilled, SvgTandCFilled } from "../components/custom-svgIcon";
import Theme from "../components/theme";

const unselectColor = '#989898'

export const GenerateSvgIconBaseOnName = (name, selectedIndex, currentIndex, showBadge) => {
    let svgIcon;
    let isSelect = selectedIndex === currentIndex ? true : false
    if (name === 'parcel_create') {
        svgIcon = <SvgOrderDollyFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'rom') {
        svgIcon = <SvgROM width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'rts') {
        svgIcon = <SvgRTSFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'parcel_history') {
        svgIcon = <SvgHistoryFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    }else if (name === 'store') {
        svgIcon = <SvgStoreFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'awb') {
        svgIcon = <SvgAWBFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'fav_location') {
        svgIcon = <SvgLocationFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    }else if (name === 'nearby_ninjas') {
        svgIcon = <SvgNearby width={23} height={23} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'ninja_rewards') {
        svgIcon = <SvgLoyaltyFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'notification') {
        svgIcon = <Badge dot={showBadge} color={Theme.colors.primary}><SvgNotificationBellFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} /></Badge>
    } else if (name === 'invoice') {
        svgIcon = <SvgInvoiceFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'guide') {
        svgIcon = <SvgLearningFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'price_coverage') {
        svgIcon = <SvgCoverageFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'faq') {
        svgIcon = <SvgFAQFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'feedback') {
        svgIcon = <SvgFeedbackFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'terms_and_condition') {
        svgIcon = <SvgTandCFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'privacy_and_policy') {
        svgIcon = <SvgPrivacyPolicyFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'pickup_call') {
        svgIcon = <SvgPickupFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'confirm_order') {
        svgIcon = <SvgConfirmOrderFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    } else if (name === 'order_create_fail_logs') {
        svgIcon = <SvgExclamationMarkFilled width={20} height={20} color={isSelect ? 'white' : unselectColor} />
    }
    return (svgIcon)
}

export const GenerateOrderStatusCode = (key, value) => {
    let code = value
    if (key === 'ARRIVED_AT_ORIGIN_HUB' || key === 'ARRIVED_AT_SORTING_HUB' || key === 'ARRIVED_AT_TRANSIT_HUB' ||
        key === 'ARRIVED_AT_DESTINATION_HUB' || key === 'IN_TRANSIT_TO_NEXT_SORTING_HUB' || key === 'AT_PUDO') {
        code = 'Processing'
    }
    return code
}

export const GetListFromEnumObject = (enumlist) => {
    let temp = []
    enumlist.map((res, index) => {
        temp.push({
            key: index + 1,
            value: res.key,
            label: res.value,
        });
    })
    return temp
}
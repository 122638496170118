import { Col, ConfigProvider, Dropdown, Input, Row, Spin, Typography } from 'antd';
import mapboxgl from 'mapbox-gl';
// import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Component } from 'react';
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';

// mapboxgl.workerClass = MapboxWorker;
mapboxgl.workerClass = function() { return new Worker(new URL('./mapboxWorker.js', import.meta.url)); }

export default class NearbyPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            map: null,
            data: [],
            searchValue: '',
        }
        this.mapContainerRef = React.createRef()
    }

    componentDidMount = async () => {
        const startPoint = [96.2072375, 16.8166942];
        let data;

        const requestParams = {
            lat: 16.8166942,
            lng: 96.2072375,
            distance: 5000
        };

        this.setState({ loading: true })
        await ApiHandler({
            url: Api.nearby,
            method: HTTP_METHOD.GET,
            mediaType: MEDIA_TYPE.JSON,
            requestParams
        }).then((response) => {
            data = response;
            this.setState({ data })
        }).catch(() => { })
            .finally(() => {
                this.setState({ loading: false })
            })

        mapboxgl.accessToken = 'pk.eyJ1Ijoibmp2bW0iLCJhIjoiY2xuMTR6cjlqMDhhYTJqb2kwbmtudDdiciJ9.we-mhra0h3nMtaH1KU0WpQ';
        
        const map = new mapboxgl.Map({
            container: this.mapContainerRef?.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: startPoint,
            zoom: 5,
        });

        this.setState({ map });
        map.addControl(new mapboxgl.NavigationControl());

        map.on('load', () => {
            map.addSource('route', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: [startPoint]
                    }
                }
            });

            map.addLayer({
                id: 'route',
                type: 'line',
                source: 'route',
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': '#3887be',
                    'line-width': 5
                }
            });

            this.addMarkers(data)
        });
    };

    addMarkers = (data) => {

        data?.map((item) => {
            const el = document.createElement('div');
            el.className = `marker ${item.nearbyType == 'PARTNERSHIP_STORE'
                ? 'ninja-partner-icon'
                : 'ninja-icon'}`;

            new mapboxgl.Marker(el)
                .setLngLat([item.longitude, item.latitude])
                .setPopup(
                    new mapboxgl.Popup({ offset: 25 })
                        .setHTML(
                            `<h3>${item.name}</h3><p>Phone: ${item.phone}</p><p>Address: ${item.address}</p> `
                        )
                )
                .addTo(this.state.map);
        })
    }

    searchMarkers = (searchTerm) => {

        if (!searchTerm) {
            this.state.map.flyTo({
                center: [96.2072375, 16.8166942],
                zoom: 5,
                essential: true
            });
            this.addMarkers(this.state.data);
            this.setState({
                filteredData: []
            })
            return
        }
        const filteredMarkers = this.state.data?.filter(item => {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.address.toLowerCase().includes(searchTerm.toLowerCase())
        }
        );

        this.setState({
            searchValue: searchTerm,
            filteredData: filteredMarkers
        });
    }

    handleSearchItemClick = (item) => {
        const { longitude, latitude } = item;

        if (this.state.map && longitude && latitude) {
            this.state.map.flyTo({
                center: [longitude, latitude],
                zoom: 14,
                essential: true
            });
        }

        this.setState({
            searchValue: '',
            filteredData: []
        });
    }

    render() {
        const { loading, filteredData } = this.state;

        const items = filteredData?.map((item, index) => ({
            key: index,
            label: <>
                <Typography.Text style={{ color: Theme.colors.primary }}>{item.name}</Typography.Text>
                <Typography.Paragraph style={{ marginBottom: 5 }}>{item.address}</Typography.Paragraph>
            </>,
            onClick: () => this.handleSearchItemClick(item)
        }))

        return (
            <Row>
                <Col lg={2} md={2} sm={1} xs={0} />
                <Col lg={20} md={20} sm={22} xs={24} style={{ position: 'relative' }}>
                    <div className="map-container">
                        <div ref={this.mapContainerRef} style={{ height: '80vh' }} />
                        {loading && <div className="loading-layer"><Spin /></div>}
                        <div style={{ position: 'absolute', top: 20, left: 10 }}>
                            <ConfigProvider theme={{
                                token: {
                                    boxShadowSecondary: 'none'
                                }
                            }}>
                                <Dropdown menu={{ items }} open={filteredData?.length > 0} disabled={filteredData?.length < 0} trigger={['click']} dropdownRender={menu => (
                                    <div style={{ maxHeight: '460px', overflowY: 'auto', width: '340px', backgroundColor: '#fff' }}>
                                        {menu}
                                    </div>
                                )}>
                                    <Input placeholder="Search" onChange={(e) => this.searchMarkers(e.target.value)} style={{ width: 200 }} />
                                </Dropdown>
                            </ConfigProvider>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}

import { Col, Flex, Form, Image, Modal, Row, Typography } from 'antd';
import { motion } from 'framer-motion';
import i18next from 'i18next';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import feeling1 from '../../asset/feeling_1.png';
import feeling2 from '../../asset/feeling_2.png';
import feeling3 from '../../asset/feeling_3.png';
import feeling4 from '../../asset/feeling_4.png';
import feeling5 from '../../asset/feeling_5.png';
import FeedbackSuccess from '../../asset/success-img.png';
import { NJVButton, NJVInput } from '../../components/core-component';
import Theme from '../../components/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../routes/custom-path';

const feelings = [
  { src: feeling1, alt: 'sad', value: 1 },
  { src: feeling2, alt: 'upset', value: 2 },
  { src: feeling3, alt: 'expressionless', value: 3 },
  { src: feeling4, alt: 'smile', value: 4 },
  { src: feeling5, alt: 'lovely', value: 5 },
];

class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRating: 0,
      loading: false,
      openSuccessModal: false,
    }
    this.formRef = React.createRef();
  }

  onFinish = async (values) => {
    const requestData = {
      rating: this.state.selectedRating,
      suggestion: values.suggestion ? values.suggestion : '',
      bugReports: values.bugReports ? values.bugReports : '',
      feedback: values.feedback ? values.feedback : ''
    }
    this.setState({ loading: true });
    await ApiHandler({ url: Api.feedbacks, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData, disableShowMessage: true })
      .then(() => {
        this.setState({ openSuccessModal: true });
      }).catch((error) => { })
      .finally(() => {
        this.setState({ loading: false });
      })
  }

  handleClick = (value) => {
    this.formRef.current.setFieldsValue({ 'rating': value })
    this.setState({ selectedRating: value })
  }

  render() {
    const { selectedRating, loading, openSuccessModal } = this.state;

    return (
      <>
        <Modal centered width={350} open={openSuccessModal} onCancel={() => {
          this.setState({ openSuccessModal: false, selectedRating: 0 });
          this.formRef.current.resetFields();
        }} footer={null}>
          <Flex vertical gap={12} align='center'>
            <Image width={100} preview={false} src={FeedbackSuccess} alt="Feedback Success" />
            <Typography.Text style={{ textAlign: 'center' }}>{i18next.t('msg_success_feedback')}</Typography.Text>
            <Link to={CustomPath.dashboard}>
              <NJVButton onClick={() => this.setState({ openSuccessModal: false })} type="primary" style={{ background: Theme.colors.primary }}>{i18next.t("go_back_to_home")}</NJVButton>
            </Link>
          </Flex>
        </Modal>
        <Row>
          <Col lg={6} md={5} sm={1} xs={1} />
          <Col lg={12} md={14} sm={22} xs={22}>
            <Typography.Title level={3} style={{ marginBottom: 20, marginTop: 0 }}>{i18next.t("feedback")}</Typography.Title>
            <Typography.Title level={5} style={{ fontWeight: 500 }}>
              Application နှင့် ပတ်သက်သည့် အကြံပြုချက်များ၊ အဆင်မပြေမှုများအား ဤနေရာတွင် ရေးသားပေးပို့နိုင်ပါသည်။
              Delivery ဝန်ဆောင်မှုများနှင့် ပတ်သက်သော အကြုံပြုချက်များကိုမူ ပိုမို မြန်ဆန်စွာဖြေရှင်းပေးနိုင်ရန် Ninja Van Myanmar Call Center (09777111001) သို့ တိုက်ရိုက် ဆက်သွယ်ပေးပါ။
            </Typography.Title>
            <Form layout='vertical' style={{ marginTop: 50 }} onFinish={this.onFinish} ref={this.formRef}>
              <Form.Item name={'rating'} label="Ninja Application အပေါ် သင်၏ထင်မြင်ချက်အားသတ်မှတ်ပါ။" rules={[{ required: true, message: '*(requried)' }]}>
                <Flex justify='space-evenly' style={{ marginTop: 20 }}>
                  {
                    feelings.map((feeling, index) => (
                      <motion.img
                        key={index}
                        src={feeling.src}
                        alt={feeling.alt}
                        width={30}
                        height={30}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.handleClick(feeling.value)}
                        animate={{ scale: selectedRating === feeling.value ? 1.5 : 1 }}
                        transition={{ duration: 0.3 }}
                      />
                    ))
                  }
                </Flex>
              </Form.Item>
              <Form.Item name={'suggestion'} label={i18next.t('feedback_suggest')}>
                <NJVInput istextareainput showCount maxLength={300} size="large" style={{ background: Theme.colors.secondary }} />
              </Form.Item>
              <Form.Item name={'bugReports'} label={i18next.t('feedback_report')}>
                <NJVInput istextareainput showCount maxLength={300} size="large" style={{ background: Theme.colors.secondary }} />
              </Form.Item>
              <Form.Item name={'feedback'} label={i18next.t('feedback_message')}>
                <NJVInput istextareainput showCount maxLength={300} size="large" style={{ background: Theme.colors.secondary }} />
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <NJVButton block loading={loading} htmlType="submit" size="large" type="primary" style={{ background: Theme.colors.primary }}>
                  {i18next.t('submit')}
                </NJVButton>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    )
  }
}

export default Feedback;
import { compose } from "@reduxjs/toolkit";
import { Col, Image, Row, Skeleton, Table, Tabs, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import AyaBank from '../../asset/aya_bank.png';
import CBBank from '../../asset/cb_bank.jpg';
import KBZBank from '../../asset/kbz_bank.jpg';
import KBZPay from '../../asset/kbz_pay.png';
import QuickPay1 from '../../asset/quick_pay_guide_one.png';
import QuickPay3 from '../../asset/quick_pay_guide_three.png';
import QuickPay2 from '../../asset/quick_pay_guide_two.png';
import YomaBank from '../../asset/yoma_bank.jpg';
import { NJVButton, NJVCard, NJVDatePicker, NJVEmptyData, NJVSelect } from "../../components/core-component";
import { SvgExcelOutlined, SvgPdfOutlined, SvgPngOutlined } from "../../components/custom-svgIcon";
import { TitleLevel3 } from "../../components/general-component";
import Theme, { Colors } from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, InvoiceDownloadByFormat } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class InvoicPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalPagination: 0,
            page: 1,
            pageSize: 20,
            last_invoice: null,
            filterData: {},
            isDataFetching: false,
            ispaymentFetching: false,
            isSendingScreenshot: false,
            isFilterAll: false,
            isFilterByDateRange: false,
            defaultFilter: 1,
            previewOpen: false,
            previewImage: '',
            fileList: [],
        }
    }

    componentDidMount() {
        this.fetchInvoice()
        this.fetchPayment()
    }

    fetchInvoice = async (pagiNumber) => {
        const { page, pageSize } = this.state
        try {
            this.setState({
                isDataFetching: true
            })

            const requestParams = {
                "page": pagiNumber ? pagiNumber - 1 : page - 1,
                "size": pageSize,
                "startDate": "",
                "endDate": "",
            }
            const response = await ApiHandler({ url: Api.shipper_invoice, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response && response.data && response.data.items) {
                this.setState({
                    data: response.data.items,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                    last_invoice: response.data.items[0],
                    isDataFetching: false,
                    page: pagiNumber ? pagiNumber : 1,
                    totalPagination: response.data.totalRecords
                })
            } else if (response && !response.success) {
                this.setState({
                    isDataFetching: false,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                })
            }

        } catch (error) {
            this.setState({
                isDataFetching: false,
                isFilterAll: true,
                isFilterByDateRange: false,
            })
        }
    }

    fetchPayment = async () => {
        this.setState({
            ispaymentFetching: true
        })
        try {
            const response = await ApiHandler({ url: Api.shipper_settlement_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.setState({
                    paymentInfo: response,
                    isPending: response.status === "PENDING" ? true : false,
                    ispaymentFetching: false
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    downloadInvoice = async (type, invoice) => {
        try {
            const param = {
                format: type
            }
            const requestBody = {
                "guid": invoice.guid
            }
            // let contentType = 'application/pdf'
            await InvoiceDownloadByFormat(Api.invoice_download, requestBody, param, invoice.merchantName, this.props)

        } catch (error) {
        }
    }

    filterBySpecific = (value) => {
        this.setState({
            defaultFilter: value
        })
        if (value === 1) {
            this.fetchInvoice()
        } else if (value === 2) {
            const endDate = dayjs();
            const startDate = endDate.subtract(7, 'day');
            this.filterByDateRage(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),)
        } else if (value === 3) {
            const endDate = dayjs();
            const startDate = endDate.subtract(30, 'day');
            this.filterByDateRage(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),)
        }
    }

    filterByDateRage = async (startDate, endDate, pagiNumber) => {
        const { page, pageSize } = this.state
        try {
            this.setState({
                isDataFetching: true,
                filterData: { 'startDate': startDate, 'endDate': endDate },
            })
            const requestParams = {
                "page": pagiNumber ? pagiNumber - 1 : page - 1,
                "size": pageSize,
                "startDate": startDate,
                "endDate": endDate,

            }
            const response = await ApiHandler({ url: Api.shipper_invoice, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response && response.data && response.data.items) {
                this.setState({
                    data: response.data.items,
                    last_invoice: response.data.items[0],
                    isDataFetching: false,
                    isFilterAll: false,
                    page: pagiNumber ? pagiNumber : 1,
                    isFilterByDateRange: true,
                    totalPagination: response.data.totalRecords,
                })
            } else if (response && !response.success) {
                this.setState({
                    isDataFetching: false,
                    isFilterAll: true,
                    isFilterByDateRange: false,
                })
            }
        } catch (error) {
            this.setState({
                isDataFetching: false,
                isFilterAll: false,
                isFilterByDateRange: true,
            })
        }
    }

    search = () => {
        const { filterData } = this.state
        if (filterData) {
            if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                message.error("Invalid date")
                return
            }
            if (filterData.fromDate > filterData.toDate) {
                message.error("Invalid date")
                return
            }
            const startDate = filterData.fromDate.format("YYYY-MM-DD")
            const endDate = filterData.toDate.format("YYYY-MM-DD")
            this.setState({
                defaultFilter: null
            })
            this.filterByDateRage(startDate, endDate)
        }
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        const { isFilterAll, isFilterByDateRange, filterData } = this.state
        if (isFilterAll) {
            this.fetchInvoice(pageNumber);
        } else if (isFilterByDateRange && filterData && filterData.startDate && filterData.endDate) {
            this.filterByDateRage(filterData.startDate, filterData.endDate, pageNumber)
        }

    };

    invoiceSection = () => {
        const { data, isDataFetching, totalPagination, page, pageSize, last_invoice, defaultFilter } = this.state

        const columns = [
            {
                title: <>{i18next.t("no")}</>,
                width: 30,
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
            },
            {
                title: 'Invoice Date',
                key: 'invoiceDate',
                render: (invoice) => {
                    let invoiceDate = ""
                    if (invoice && invoice.invoiceDate) {
                        const convertedInvoiceDate = dayjs(invoice.invoiceDate, 'YYYY-MM-DD');
                        if (convertedInvoiceDate) {
                            invoiceDate = convertedInvoiceDate.format('DD-MMM-YYYY')
                        }
                    }
                    return <span>{invoiceDate}</span>
                }
            }, {
                title: 'PDF',
                width: 50,
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('pdf', invoice)}><SvgPdfOutlined width={25} height={25} color={Colors.primary} /></NJVButton>
                ),
            }, {
                title: 'Image',
                width: 50,
                render: (invoice) => (
                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('png', invoice)}><SvgPngOutlined width={25} height={25} color={Colors.primary} /></NJVButton>
                ),
            }, {
                title: 'Excel',
                width: 50,
                render: (invoice) => (

                    <NJVButton size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('excel', invoice)}><SvgExcelOutlined width={25} height={25} color={Colors.primary} /></NJVButton>
                ),
            }
        ]

        return (
            <Row gutter={[16, 16]}>
                {
                    last_invoice ?
                        <Col span={24}>

                            <table style={{ marginBottom: 30 }}>
                                <tr style={{ fontSize: 17, fontWeight: '600', height: 30 }}>
                                    <td>Invoice No </td>
                                    <td>:</td>
                                    <td>{last_invoice.invoiceNo}</td>
                                </tr>
                                <tr style={{ fontSize: 15, height: 30 }}>
                                    <td>Invoice Date </td>
                                    <td>:</td>
                                    <td>{last_invoice.invoiceDate}</td>
                                </tr>
                                <tr style={{ fontSize: 15, height: 30 }}>
                                    <td>Value </td>
                                    <td>:</td>
                                    <td><span style={{ color: 'green', fontWeight: '500' }}>{last_invoice.transferAmount}</span></td>
                                </tr>
                            </table>
                        </Col>
                        : <></>
                }

                {
                    last_invoice ?
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col lg={5} md={8} sm={12} xs={12}>
                                    <NJVButton type="primary" size="large" onClick={() => this.downloadInvoice('pdf', last_invoice)} style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', width: '100%' }}><SvgPdfOutlined width={20} height={20} color={'white'} /><span style={{ marginLeft: 5 }}>{i18next.t('download_pdf')}</span></NJVButton>
                                </Col>
                                <Col lg={5} md={8} sm={12} xs={12}>
                                    <NJVButton type="primary" size="large" onClick={() => this.downloadInvoice('png', last_invoice)} style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', width: '100%' }}><SvgPngOutlined width={20} height={20} color={'white'} /><span style={{ marginLeft: 5 }}>{i18next.t('download_image')}</span></NJVButton>
                                </Col>
                                <Col lg={5} md={8} sm={12} xs={12}>
                                    <NJVButton type="primary" size="large" onClick={() => this.downloadInvoice('excel', last_invoice)} style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', width: '100%' }}><SvgExcelOutlined width={20} height={20} color={'white'} /><span style={{ marginLeft: 5 }}>{i18next.t('download_excel')}</span></NJVButton>
                                </Col>
                            </Row>
                        </Col>
                        :
                        <></>
                }
                <Col span={24} style={{ marginBottom: 20, marginTop: 20 }}>
                    <Row gutter={[16, 16]}>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <NJVSelect
                                size="large"
                                bgcolor={Theme.colors.secondary}
                                style={{
                                    width: '100%'
                                }}
                                placeholder="Select"
                                value={defaultFilter}
                                onChange={(value) => this.filterBySpecific(value)}
                                options={[
                                    {
                                        'key': 1,
                                        'label': <>{i18next.t('order_billing')}</>,
                                        'value': 1
                                    }, {
                                        'key': 2,
                                        'label': 'Last 7 days',
                                        'value': 2
                                    }, {
                                        'key': 3,
                                        'label': 'Last 30 days',
                                        'value': 3
                                    }
                                ]}
                            />
                        </Col>

                        <Col lg={6} md={6} sm={24} xs={24}>
                            <NJVDatePicker
                                onChange={(value) => this.updateFilterData('fromDate', value)}
                                style={{ width: '100%' }}
                                bgcolor={Theme.colors.secondary}
                                size="large"
                                placeholder={i18next.t("from_date")} />
                        </Col>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <NJVDatePicker
                                onChange={(value) => this.updateFilterData('toDate', value)}
                                bgcolor={Theme.colors.secondary}
                                style={{ width: '100%' }}
                                size="large"
                                placeholder={i18next.t("to_date")} />
                        </Col>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <NJVButton
                                size="large"
                                style={{ width: '100%' }}
                                type="primary" onClick={() => this.search()}>
                                {i18next.t("search")}
                            </NJVButton>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton />
                            :
                            <Table
                                rowKey={(record) => record.id}
                                className="custom-table"
                                style={{ borderRadius: 10 }}
                                pagination={{
                                    position: 'top' | 'bottom',
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                    showSizeChanger: false
                                }}
                                columns={columns} dataSource={data} />
                    }
                </Col>
            </Row>
        )
    }

    paymentSection = () => {
        const { ispaymentFetching, paymentInfo, isPending } = this.state

        const styles = {
            colStyle: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        };

        const imageContainer = {
            border: '1px solid #ccc',
            width: 90,
            height: 90,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 12
        }

        const bankLinksData = [
            {
                path: CustomPath.payment_info,
                bankData: {
                    name: 'YOMA Bank',
                    photo: YomaBank,
                    style: imageContainer,
                    account: {
                        acc1: '007 1111 4300 3305 (Flexi Everyday Account)',
                    },
                    id: paymentInfo?.id
                },
            },
            {
                path: CustomPath.payment_info,
                bankData: {
                    name: 'AYA Bank',
                    photo: AyaBank,
                    style: imageContainer,
                    account: {
                        acc1: '100 0404 1670 (Normal Account)',
                        acc2: '100 0418 9607 (Special Account)',
                    },
                    id: paymentInfo?.id
                },
            },
            {
                path: CustomPath.payment_info,
                bankData: {
                    name: 'KBZ Bank',
                    photo: KBZBank,
                    style: imageContainer,
                    account: {
                        acc1: '06010 3060 0563 2301 (Normal Account)',
                        acc2: '06013 9060 0623 6001 (Special Account)',
                    },
                    id: paymentInfo?.id
                },
            },
            {
                path: CustomPath.payment_info,
                bankData: {
                    name: 'CB Bank',
                    photo: CBBank,
                    style: imageContainer,
                    account: {
                        acc1: '0183 1009 0000 7846',
                    },
                    id: paymentInfo?.id
                },
            },
            {
                path: CustomPath.payment_info,
                bankData: {
                    name: 'KBZPay',
                    photo: KBZPay,
                    quickPay: true,
                    account: {
                        acc1: QuickPay1,
                        acc2: QuickPay2,
                        acc3: QuickPay3,
                    },
                    imgWidth: 100,
                    id: paymentInfo?.id
                },
            },
        ];

        const BankLink = ({ path, bankData }) => {

            return (
                <Col lg={4} md={6} sm={12} xs={12} style={styles.colStyle}>
                    <Link to={path} state={bankData}>
                        <div style={bankData.style || {}}>
                            <Image width={bankData.imgWidth ? bankData.imgWidth : 80} src={bankData.photo} preview={false} style={{ cursor: 'pointer', objectFit: 'cover' }} />
                        </div>
                    </Link>
                </Col>
            );
        };

        return (
            <>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        {
                            ispaymentFetching ?
                                <Skeleton />
                                :
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 20 }}>
                                        <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.props.navigate(CustomPath.payment_history)}>{i18next.t("view_history")}</NJVButton>
                                    </div>
                                    {
                                        isPending ?
                                            <>
                                                <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
                                                    <Col span={24}>
                                                        <NJVCard style={{ marginBottom: 30 }}>
                                                            <p style={{ fontWeight: 600, textAlign: 'center' }}>{paymentInfo?.content}</p>
                                                        </NJVCard>
                                                    </Col>
                                                    <Col span={24} style={{ marginBottom: 20 }}>
                                                        <TitleLevel3 label="Choose Payment Method" />
                                                    </Col>
                                                    {bankLinksData.map((linkData, index) => (
                                                        <BankLink key={index} path={linkData.path} bankData={linkData.bankData} />
                                                    ))}
                                                </Row>
                                            </> :
                                            <Row>
                                                <Col span={24}>
                                                    <NJVEmptyData label={i18next.t("msg_settlement")} />
                                                </Col>
                                            </Row>
                                    }
                                </>
                        }
                    </Col>
                </Row>
            </>
        )
    }

    render() {

        const items = [
            {
                key: '1',
                label: <TitleLevel3 label={i18next.t("invoice")} />,
                children: this.invoiceSection(),
            },
            {
                key: '2',
                label: <TitleLevel3 label={i18next.t("credit_payment")} />,
                children: this.paymentSection(),
            },
        ]

        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Tabs defaultActiveKey={`${this.props.isSettlement ? "2" : "1"}`} items={items} />
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(InvoicPage)
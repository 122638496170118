
export const PhoneNumberValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 6 || value.length > 11 || !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};

export const RestrictPhoneNumberValidator = (_, value) => {
    const numberPattern = /^(09\d{7,9}|01\d{7})$/;
    if (!numberPattern.test(value)) {
        return Promise.reject('Phone number must start with "09" or "01"');
    }
    return Promise.resolve();
};

export const NRCValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 6 || value.length > 6 || !numberPattern.test(value)) {
        return Promise.reject('Invalid NRC Number');
    }
    return Promise.resolve();
};

export const NumberOnlyValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    if (!value || value.length < 6 || value.length > 17 || !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};

export const NumberOnlyForOptionalValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    let isValueExist = false
    if (value && (value.length < 6 || value.length > 16)) {
        return Promise.reject('Invalid input');
    }
    if (value && value.length !== 0) {
        isValueExist = true
    }
    if (isValueExist && !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};

export const ParcelAmountValidator = (_, value) => {
    const numberPattern = /^[0-9]+$/;
    let isValueExist = false
    if (value && (value.length < 3 || value.length > 7)) {
        return Promise.reject('Invalid input');
    }
    if (value && value.length !== 0) {
        isValueExist = true
    }
    if (isValueExist && !numberPattern.test(value)) {
        return Promise.reject('Invalid input');
    }
    return Promise.resolve();
};
import { Col, Divider, Row, Typography } from 'antd'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { NJVButton } from '../../components/core-component'
import Theme from '../../components/theme'
import CustomPath from '../../routes/custom-path'

export default class HowItWork extends Component {
    render() {
        return (
            <Row>
                <Col span={24}>
                    <Typography.Title level={4} style={{ margin: 0, marginBlock: 20 }}>How it works?</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, marginBlock: 20 }}>အဖွဲ့ဝင်ခြင်း</Typography.Title>
                    <Typography.Text>Ninja Van Myanmar App ဖြင့် Register လုပ်ထားသူတိုင်း Ninja Reward Program တွင်ပါဝင်ခွင့်ရှိပါသည်။</Typography.Text>
                </Col>
                <Divider variant='dashed' style={{ marginBottom: 0, borderColor: '#0f0' }} />
                <Col span={24}>
                    <Typography.Title level={5} style={{ margin: 0, marginBlock: 20 }}>ပွိုင့်ရယူနိုင်သောနည်းလမ်းများ</Typography.Title>
                    <Typography.Text>Ninja Van Myanmar App အသုံးပြုပြီး ပါဆယ်ပို့ဆောင်ခြင်းအောင်မြင်သွားပါက ပစ္စည်းပို့ဆောင်သူမှ ပွိုင့် များရရှိမည်ဖြစ်ပါသည်။ Completed Tracking တစ်ကြောင်းအတွက် ပွိုင့် ၁ ပွိုင့်ရရှိမည်ဖြစ်ပါသည်။ </Typography.Text>
                </Col>
                <Divider variant='dashed' style={{ marginBottom: 0, borderColor: '#0f0' }} />
                <Col span={24}>
                    <Typography.Title level={5} style={{ margin: 0, marginBlock: 20 }}>အဖွဲ့ဝင်အဆင့် သတ်မှတ်ချက်များ</Typography.Title>
                    <Typography.Text>Ninja Reward Program အနေဖြင့် Shipper, Gold နှင့် Platinum အဖွဲ့ဝင်အဆင့်သုံးဆင့်ကို ဂုဏ်ယူစွာပေးအပ်ထားပြီး အဖွဲ့ဝင်အဆင့်တိုင်းတွင် သီးခြားအကျိုးခံစားခွင့်များ ရှိမည်ဖြစ်ပါသည်။ Ninja Rewards အကျိုးခံစားခွင့်အစီအစဉ် အဖွဲ့ဝင်တစ်ဦးအနေဖြင့် ပါဆယ်ပိုမို အပ်နှံ လေ ပိုမိုအကျိုးခံစားခွင့်ရလေဖြစ်မည်။ သင့်အဖွဲ့ဝင်အဆင့်မြင့်လေ အကျိုးခံစားခွင့် ပိုမိုရရှိလေဖြစ်မည်ဖြစ်ပါသည်။</Typography.Text>
                </Col>
                <Col span={24}>
                    <table style={{ border: '1px solid #ccc', borderCollapse: 'collapse', marginBlock: 20 }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #ccc', padding: 10 }}>အဖွဲ့ဝင်အဆင့်သတ်မှတ်ချက်</th>
                                <th style={{ border: '1px solid #ccc', padding: 10 }}>ပို့ဆောင်အောင်မြင်သည့် tracking အရေအတွက်</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ border: '1px solid #ccc', padding: 8 }}>Shipper</td>
                                <td style={{ border: '1px solid #ccc', padding: 8 }}>1 - 9</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ccc', padding: 8 }}>Gold</td>
                                <td style={{ border: '1px solid #ccc', padding: 8 }}>10 - 199</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ccc', padding: 8 }}>Platinum</td>
                                <td style={{ border: '1px solid #ccc', padding: 8 }}>200 and above</td>
                            </tr>
                        </tbody>
                    </table>
                    <Typography.Text style={{ color: Theme.colors.primary }}>တစ်လအတွင်း ပို့ဆောင်အောင်မြင်သည့်အရေအတွက်အလိုက် အဖွဲ့၀င် အဆင့်သတ်မှတ်ချက်များပြောင်းလဲသွားမည်ဖြစ်ပါသည်။</Typography.Text>
                </Col>
                <Divider variant='dashed' style={{ marginBottom: 0, borderColor: '#0f0' }} />
                <Col span={24}>
                    <Typography.Title level={5} style={{ margin: 0, marginBlock: 20 }}>ရရှိနိုင်သောအကျိုးခံစားခွင့်များ</Typography.Title>
                    <Typography.Text>
                        Ninja Van မှချိတ်ဆက်ထားသော စားသောက်ဆိုင်များ၊ fashion ဆိုင်များ၊ ဟိုတယ်၊ အလှအပနှင့် အခြားသောဆိုင်များတွင် Ninja Van Myanmar App ထဲမှ အဖွဲ့ဝင် digital card ပြပြီး အထူးလျော့စျေးများရရှိနိုင်မည်ဖြစ်ပါသည်။ အဖွဲ့ဝင်အဆင့်အလိုက် ရရှိနိုင်သောလျော့စျေးမှာ ကွဲပြားသွားမည်ဖြစ်ပါသည်။
                    </Typography.Text>
                </Col>
                <Col span={24} style={{ marginTop: 20 }}>
                    <Link to={CustomPath.loyalty_faq} state={{loyaltyFaq: true}}>
                        <NJVButton size="large" primarycolor={Theme.colors.primary} style={{ width: '100%' }}>
                            <span style={{ fontWeight: 'bold', color: '#fff' }}>FAQ</span>
                        </NJVButton>
                    </Link>
                </Col>
            </Row>
        )
    }
}

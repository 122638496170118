import {
    LoadingOutlined
} from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Carousel, Col, Divider, Form, Image, Radio, Row, Skeleton, Spin } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import Lottie from "lottie-react";
import React from "react";
import OrderConfirmAnimation from '../../animate-lottie/lottie-quick-voucher-order-created';
import ProessingAnimation from '../../animate-lottie/lottie-quick-voucker-pending.json';
import { NJVButton, NJVInput, NJVSelect } from "../../components/core-component";
import { SvgBusinessOutlined, SvgCheckCircleFill, SvgStandPhoneOutlined } from "../../components/custom-svgIcon";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandlerNoAuth } from "../../network/network-manager";
import withRouter from '../../network/with-router';
import { logClick, logView } from '../../firebase/analyticsUtils';
import ThumbnailImg from '../../asset/ninja_thumbnail.jpg'

const HOME_DELIVERY = 1
const BUS_GATE = 2

const COLLECT_FROM_CUSTOMER = 1
const PAY_BY_SHIPPER = 2

class QuickVoucherCustomerConfirm extends React.Component {

    // NVM3608bb199ae54e3c85d000c6b61ecbb1
    constructor(props) {
        super(props)
        this.state = {
            voucherId: null,
            is_data_editable: true,
            orderData: null,
            data: {},
            userInput: {},
            divisionList: [],
            division_collection_home_delivery: [],
            township_map_home_delivery: [],
            division_collection_bus_gate_service: [],
            township_map_bus_gate_service: [],
            is_data_fetching: true,
            is_busgate_data_fetching: false,
            is_fetching_price_script: false,
            is_voucher_confirming: false,
            imageItems: [],
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        const currentUrl = window.location.href;
        const index = currentUrl.indexOf('uniqueId=');
        if (index !== -1) {
            const substring = currentUrl.substring(index + 'uniqueId='.length);
            this.setState({ voucherId: substring })
        }
        this.fetchDivisionAndTownship()
        this.fetchImageData()

        this.setState({
            userInput: { orderType: 1 }
        })
        logView('public_confirm_order_screen')
    }

    fetchDivisionAndTownship = async () => {
        try {
            const response = await ApiHandlerNoAuth({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.setState({
                    division_collection: response,
                    isFinishFetchingData: true
                })
                let divisionListHomeDelivery = []
                let townshipMapHomeDelivery = {}
                let divisionListBusGateSevice = []
                let townshipMapBusGateService = {}

                response.forEach(division => {
                    if (division.active) {
                        divisionListBusGateSevice.push(division)
                        let townshipListBusGateService = []
                        division.townshipDTOList && division.townshipDTOList.forEach(township => {
                            if (township.active && township.enableBusGatePostOfficeService) {
                                townshipListBusGateService.push(township)
                            }
                        })
                        const sortedTownshipListBusGateService = townshipListBusGateService.sort((a, b) => {
                            if (a.name < b.name) return -1
                            if (a.name > b.name) return 1
                            return 0
                        })
                        townshipMapBusGateService[division.id] = sortedTownshipListBusGateService
                    }

                    if (division.active && division.enableHomeDelivery) {
                        divisionListHomeDelivery.push(division)
                        let townshipListHomeDelivery = []
                        division.townshipDTOList && division.townshipDTOList.forEach(township => {
                            if (township.active && township.enableHomeDelivery) {
                                townshipListHomeDelivery.push(township)
                            }
                        })
                        const sortedTownshipListHomeDelivery = townshipListHomeDelivery.sort((a, b) => {
                            if (a.name < b.name) return -1
                            if (a.name > b.name) return 1
                            return 0
                        })
                        townshipMapHomeDelivery[division.id] = sortedTownshipListHomeDelivery
                    }
                })
                this.setState({
                    divisionList: divisionListHomeDelivery,
                    division_collection_home_delivery: divisionListHomeDelivery,
                    township_map_home_delivery: townshipMapHomeDelivery,
                    division_collection_bus_gate_service: divisionListBusGateSevice,
                    township_map_bus_gate_service: townshipMapBusGateService
                }, () => this.fetchData())
            }
        } catch (error) {
            this.setState({
                is_data_fetching: false
            })
        }

    }

    fetchData = async () => {
        let { userInput, township_map_home_delivery, township_map_bus_gate_service, voucherId } = this.state
        try {
            const requestParams = {
                uniqueId: voucherId
            }
            const response = await ApiHandlerNoAuth({ url: Api.get_voucher_by_unique_id, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let townshipList = []
                const divisionId = response.customerTownship?.division.id;
                const townshipId = response.customerTownship?.id;
                const paymentOption = response.paymentOption;
                userInput = {
                    ...userInput,
                    deliveryCharges: response.deliveryCharges,
                    parcelAmount: response.parcelAmount,
                    divisionId: response.customerTownship ? divisionId : null,
                    customerName: response.customerName,
                    customerPhoneNumber: response.customerPhoneNo,
                    address: response.addressDetail,
                    paymentOption: paymentOption && paymentOption === "Collect from customer" ? 1 : paymentOption && paymentOption === "Pay by shipper" ? 2 : "Collect from customer"
                }
                if (response.orderType === 'Home Delivery') {
                    userInput = { ...userInput, orderType: HOME_DELIVERY }//, paymentOption: COLLECT_FROM_CUSTOMER
                    if (response.customerTownship) {
                        townshipList = township_map_home_delivery[divisionId]
                        userInput = { ...userInput, townshipId: townshipId }
                    }
                } else if (response.orderType === 'Bus Gate') {
                    userInput = { ...userInput, orderType: BUS_GATE, paymentOption: PAY_BY_SHIPPER }
                    if (response.customerTownship) {
                        townshipList = township_map_bus_gate_service[divisionId]
                        userInput = { ...userInput, townshipId: townshipId }
                    }
                    if (response.busGateId) {
                        userInput = { ...userInput, busGateId: response.busGateId, deliveryCharges: 1000 }
                    } else {
                        userInput = { ...userInput, deliveryCharges: response.deliveryCharges ? response.deliveryCharges : 0 }
                    }
                }

                if (response.status === 'CONFIRM' && response.orderTrackingNumber) {
                    this.fetchOrderByTrackingNumber(response.orderTrackingNumber)
                }
                this.setState({
                    userInput: userInput,
                    data: response,
                    is_data_fetching: false,
                    townshipList: townshipList,
                    is_data_editable: response.status && response.status === 'PENDING' ? true : false,
                }, () => {
                    this.fetchBusGate(townshipId, BUS_GATE)
                    this.formRef.current.setFieldsValue(userInput)
                })
            }
        } catch (error) {
            console.log(error);
            this.setState({
                is_data_fetching: false
            })
        }
    }

    fetchOrderByTrackingNumber = async (tracking_number) => {
        try {
            const requestParams = {
                trackingNumber: tracking_number
            }
            let response = await ApiHandlerNoAuth({ url: Api.public_order_get_by_tracking_number, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                const eventTime = dayjs(response.modifiedDate, 'YYYY-MM-DD HH:mm:ss');
                if (eventTime) {
                    response = { ...response, modifiedDate: eventTime.format('YYYY-MMM-DD hh:mm A') }
                }
                this.setState({
                    orderData: response
                })
            }
        } catch (error) {

        }
    }

    fetchBusGate = async (townshipId, orderType) => {
        const { data, userInput } = this.state
        let type = orderType ? orderType : userInput.orderType
        try {
            if (type === BUS_GATE) {
                this.setState({
                    busGateList: [],
                    is_busgate_data_fetching: true
                })
                const requestParams = {
                    'receiverTownshipId': townshipId ? townshipId : userInput.townshipId,
                    'senderLocationId': data.shipperInfo.townshipId
                }
                const response = await ApiHandlerNoAuth({ url: Api.public_bus_gate_by_receiver_and_sender, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
                if (response) {
                    this.setState({
                        busGateList: response
                    })
                }
            }
        } catch (error) {
        }
        this.setState({
            is_data_fetching: false,
            is_busgate_data_fetching: false
        })
    }

    fetchImageData = async () => {
        this.setState({
            isFetchingImageData: true
        })
        const requestParams = {
            'bannerType': 'LOYALTY_SHOP_DASHBOARD'
        }
        await ApiHandlerNoAuth({ url: Api.banner_slide, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    isFetchingImageData: false
                })
                response.forEach((item, index) => {
                    this.setState((prevState) => ({
                        imageItems: [...prevState.imageItems, item]
                    }
                    ))
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isFetchingImageData: false
                })
            })
    }

    handleUserInput = (key, value) => {
        let { data, userInput, township_map_bus_gate_service, township_map_home_delivery, busGateList } = this.state
        userInput = {
            ...userInput,
            [key]: value
        }

        if (key === 'divisionId') {
            let townshipList = []
            userInput = { ...userInput, townshipId: null, busGate: null, busGateList: [], busGateId: null }
            if (userInput.orderType === HOME_DELIVERY) {
                townshipList = township_map_home_delivery[value]
            } else if (userInput.orderType === BUS_GATE) {
                townshipList = township_map_bus_gate_service[value]
            }
            this.setState({
                townshipList: townshipList
            })
        } else if (key === 'townshipId') {
            userInput = { ...userInput, busGate: null, busGateList: [], busGateId: null }
            if (userInput.orderType === HOME_DELIVERY) {
                this.getPriceScript(data.shipperInfo.townshipId, value, userInput)
            } else if (userInput.orderType === BUS_GATE) {
                this.fetchBusGate(value, BUS_GATE)
            }
        } else if (key === 'orderType') {
            let townshipList = []
            if (value === HOME_DELIVERY && userInput.divisionId) {
                townshipList = township_map_home_delivery[userInput.divisionId]
            } else if (value === BUS_GATE && userInput.divisionId) {
                townshipList = township_map_bus_gate_service[userInput.divisionId]
            }
            userInput = {
                ...userInput,
                townshipId: null,
                busGateId: null,
                address: '',
                deliveryCharges: value === HOME_DELIVERY ? 0 : 2000,
                paymentOption: value === HOME_DELIVERY ? userInput.paymentOption : value === BUS_GATE ? PAY_BY_SHIPPER : COLLECT_FROM_CUSTOMER
            }
            this.setState({ townshipList: townshipList })
        } else if (key === 'busGateId') {
            let busGateName = ''
            busGateList?.forEach(busgate => {
                if (busgate.id === value) {
                    busGateName = busgate.busGateName
                    return
                }
            })
            userInput = { ...userInput, address: busGateName }
        }

        this.setState({
            userInput: userInput,
        })
        const { busGateId, divisionId, orderType, paymentOption, townshipId } = userInput;
        this.formRef.current.setFieldsValue({ busGateId, divisionId, orderType, paymentOption, townshipId })
    }

    getPriceScript = async (senderTownshipId, receiverTownshipId, userInput) => {
        const { data } = this.state
        this.setState({
            is_fetching_price_script: true
        })
        try {
            const requestParams = {
                'senderTownshipId': senderTownshipId,
                'receiverTownshipId': receiverTownshipId,
                'specificUserId': data.shipperInfo.id
            }
            const response = await ApiHandlerNoAuth({ url: Api.get_price, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response || response === 0) {
                this.setState({
                    userInput: { ...userInput, deliveryCharges: response }
                })
            }
        } catch (error) {
        }
        this.setState({
            is_fetching_price_script: false
        })
    }

    customConfirmVoucher = async (values) => {
        let { voucherId, userInput } = this.state
        const { address, customerName, customerPhoneNumber } = values;

        this.setState({
            is_voucher_confirming: true
        })
        try {
            userInput = {
                ...userInput,
                address,
                customerName,
                customerPhoneNumber,
                "deliveryCharges": userInput.deliveryCharges ? +userInput.deliveryCharges : 0,
                "parcelAmount": userInput.parcelAmount ? +userInput.parcelAmount : 0
            }
            if (userInput.paymentOption === COLLECT_FROM_CUSTOMER) {
                userInput = {
                    ...userInput,
                    address,
                    customerName,
                    customerPhoneNumber,
                    "codAmount": userInput.deliveryCharges + userInput.parcelAmount
                }
            } else {
                userInput = {
                    ...userInput,
                    address,
                    customerName,
                    customerPhoneNumber,
                    "codAmount": userInput.parcelAmount
                }
            }


            let requestData = {
                "uniqueId": voucherId,
                "customerPhoneNo": userInput.customerPhoneNumber ? userInput.customerPhoneNumber : null,
                "customerName": userInput.customerName ? userInput.customerName : null,
                "addressDetail": userInput.address ? userInput.address : null,
                "townshipId": userInput.townshipId ? userInput.townshipId : null,
                "orderType": userInput.orderType && userInput.orderType === HOME_DELIVERY ? 'Home Delivery' : userInput.orderType && userInput.orderType === BUS_GATE ? 'Bus Gate' : 'Home Delivery',
                "busGateId": userInput.busGateId ? userInput.busGateId : null,
                "isSameDay": false,
                "paymentOption": userInput.paymentOption && userInput.paymentOption === 1 ? "Collect from customer" : userInput.paymentOption && userInput.paymentOption === 2 ? "Pay by shipper" : "Collect from customer",
                "deliveryCharges": userInput.deliveryCharges,
                "codAmount": userInput.codAmount,
                "parcelAmount": userInput.parcelAmount
            }
            const response = await ApiHandlerNoAuth({ url: Api.update_voucher, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData })
            if (response) {
                this.fetchData()
            }
            this.setState({
                is_voucher_confirming: true
            })

        } catch (error) {

        }
    }

    filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    render() {
        const { is_voucher_confirming, orderData, data, userInput, divisionList, townshipList, is_data_fetching, busGateList, is_busgate_data_fetching, is_fetching_price_script, is_data_editable, isFetchingImageData, imageItems } = this.state
        const orderTypeList = [
            {
                id: 1,
                value: <>{i18next.t("home_delivery")}</>
            }, {
                id: 2,
                value: <>{i18next.t("bus_gate")}</>
            }
        ]
        return (
            <Row gutter={[16, 16]}>
                <Col lg={4} md={3} sm={1} xs={1} />
                <Col lg={16} md={18} sm={22} xs={22} >
                    <Row gutter={[20, 20]}>
                        {
                            is_data_fetching ?
                                <Skeleton active />
                                :
                                <>

                                    {
                                        data && data.shipperInfo ?
                                            <>

                                                <Col span={24} style={{ textAlign: 'center', marginTop: 30 }}>
                                                    {
                                                        data.status && data.status === 'READY' ?
                                                            <div style={{ marginBottom: 50, textAlign: 'center' }}>
                                                                <Lottie
                                                                    style={{ height: '300px' }}
                                                                    animationData={ProessingAnimation}
                                                                    loop={true}
                                                                    autoplay={true}
                                                                />
                                                                <span style={{ fontSize: 18, fontWeight: '600' }}>You've confirmed the order, and it's processing.</span>
                                                            </div>
                                                            :
                                                            <>
                                                                {
                                                                    data.status && data.status === 'CONFIRM' ?
                                                                        <div style={{ marginBottom: 50 }}>
                                                                            <Lottie
                                                                                style={{ height: '300px', marginBottom: 20 }}
                                                                                animationData={OrderConfirmAnimation}
                                                                                loop={true}
                                                                                autoplay={true}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <></>

                                                                }
                                                            </>
                                                    }

                                                    {
                                                        orderData ?
                                                            <>
                                                                <Divider orientation="left" orientationMargin="0">Order Updated Information</Divider>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div style={{ marginRight: 10, marginTop: 3 }}>
                                                                            <SvgCheckCircleFill height={20} width={20} color={Theme.colors.success} />
                                                                        </div>
                                                                        <span style={{ marginRight: 8, fontSize: 15, fontWeight: '500' }}> {orderData.ninjaCoreOrderStatus}</span>
                                                                    </div>
                                                                    <span style={{ fontSize: 15 }}>{orderData.modifiedDate}</span>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                    <Divider orientation="left" orientationMargin="0">Shipper Information</Divider>

                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                                        <span style={{ marginRight: 8 }}><SvgBusinessOutlined width={20} height={20} color={Theme.colors.primary} /></span>
                                                        <span>{data.shipperInfo.businessName}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span style={{ marginRight: 8 }}><SvgStandPhoneOutlined width={20} height={20} color={Theme.colors.primary} /></span>
                                                        <span>{data.shipperInfo.phoneNumber}</span>
                                                    </div>

                                                </Col>
                                                {
                                                    imageItems &&
                                                    <Col span={24}
                                                    >
                                                        {isFetchingImageData ? <Skeleton active /> :
                                                            <Carousel
                                                                style={{ textAlign: 'center' }}
                                                                draggable={true}
                                                                arrows
                                                                autoplay
                                                                // autoplaySpeed={2000}
                                                                infinite
                                                                >
                                                                {
                                                                    imageItems?.map((item) => (
                                                                        <div key={item.id}
                                                                        >
                                                                            <a
                                                                                href={item.link && item.link !== '' ? item.link : ''}
                                                                                target={item.link && item.link !== '' ? '_blank' : '_self'}
                                                                                onClick={() => logClick('public_confirm_order_screen', item.name)}
                                                                            >
                                                                                <Image
                                                                                    alt={item.name}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        height: 200,
                                                                                        borderRadius: 10,
                                                                                        objectFit: 'cover',
                                                                                        cursor: item.link && item.link !== '' ? 'pointer' : 'default'
                                                                                    }}
                                                                                    src={item.photoUrl}
                                                                                    preview={false}
                                                                                    fallback={ThumbnailImg}
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                            </Carousel>
                                                        }
                                                    </Col>
                                                }
                                                <Col span={24}>
                                                    <Divider orientation="left" orientationMargin="0">Items</Divider>

                                                    <Row
                                                        style={{ padding: 10, borderRadius: 8, backgroundColor: '#f1f1f1', marginBottom: 0.7, textAlign: 'center' }}>
                                                        <Col span={2}>
                                                            <span>No.</span>
                                                        </Col>
                                                        <Col span={14}>
                                                            <span>Item Name</span>
                                                        </Col>
                                                        <Col span={3}>
                                                            <span>QTY</span>
                                                        </Col>
                                                        <Col span={5}>Amount</Col>

                                                    </Row>
                                                    {
                                                        data && data.shippingItems && data.shippingItems.map((item, index) => (
                                                            <Row
                                                                key={item.id}
                                                                style={{ padding: 10, borderRadius: 8, backgroundColor: '#f1f1f1', marginBottom: 1, textAlign: 'center' }}>
                                                                <Col span={2}>
                                                                    <span>{index + 1}</span>
                                                                </Col>
                                                                <Col span={14}>
                                                                    <span>{item.name}</span>
                                                                </Col>
                                                                <Col span={3}>
                                                                    <span>{item.quantity}</span>
                                                                </Col>
                                                                <Col span={5}>
                                                                    <span>{item.price}</span>
                                                                </Col>
                                                            </Row>

                                                        ))
                                                    }
                                                    <Row>
                                                        <Col span={14} style={{ textAlign: 'center' }}></Col>
                                                        <Col span={10} style={{ padding: 10, borderRadius: 8, backgroundColor: '#f1f1f1', marginBottom: 1, textAlign: 'center' }}>
                                                            <Row>
                                                                <Col span={12} style={{ textAlign: 'center' }}>Total</Col>
                                                                <Col span={12}>{data.parcelAmount}</Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Divider orientation="left" orientationMargin="0">Order Information (Optional)</Divider>

                                                <Form ref={this.formRef} autoComplete="off" onFinish={this.customConfirmVoucher} layout='vertical' style={{ width: '100%', marginBottom: 20 }}>
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={24}>
                                                            <Form.Item name='orderType' label={i18next.t("order_type")} rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Choose order type'
                                                                }
                                                            ]}>
                                                                <Radio.Group disabled={!is_data_editable} value={userInput && userInput.orderType ? userInput.orderType : 1} onChange={(event) => this.handleUserInput('orderType', event.target.value)}
                                                                >
                                                                    {
                                                                        orderTypeList.map((order) => {
                                                                            return <Radio value={order.id}>{order.value}</Radio>
                                                                        })
                                                                    }
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item name="customerName" label={i18next.t("receiver_name")} rules={[
                                                                {
                                                                    required: true,
                                                                    message: i18next.t('err_msg_enter_receiver_name')
                                                                },
                                                                {
                                                                    max: 50,
                                                                    message: i18next.t('Name cannot exceed 50 characters.'),
                                                                }
                                                            ]}>
                                                                <NJVInput disabled={!is_data_editable} size="large" bgcolor={Theme.colors.secondary} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item name="customerPhoneNumber" label={i18next.t("receiver_phone_number")} rules={[
                                                                {
                                                                    required: true,
                                                                    message: i18next.t('err_msg_enter_receiver_phone_number')
                                                                },
                                                                {
                                                                    pattern: /^[0-9]+$/,
                                                                    message: i18next.t('err_msg_invalid_phone_number')
                                                                },
                                                                {
                                                                    min: 6,
                                                                    // message: i18next.t('err_msg_phone_number_too_short')
                                                                    message: i18next.t('Phone Number is too short')
                                                                },
                                                                {
                                                                    max: 11,
                                                                    message: i18next.t('err_msg_invalid_phone_number')
                                                                }
                                                            ]}>
                                                                <NJVInput disabled={!is_data_editable} size="large" bgcolor={Theme.colors.secondary} style={{ background: '#f1f1f1' }} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item name="divisionId" label={i18next.t("division_state")} rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Select division"
                                                                }
                                                            ]} >
                                                                <NJVSelect disabled={!is_data_editable} bgcolor={Theme.colors.secondary} size="large" showSearch filterOption={this.filterOption} options={
                                                                    divisionList && divisionList.map((division) => {
                                                                        return {
                                                                            value: division.id,
                                                                            label: division.name,
                                                                            type: 'division'
                                                                        }
                                                                    })
                                                                } onChange={(event) => this.handleUserInput('divisionId', event)} placeholder={i18next.t("division_state")} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item name="townshipId" label={i18next.t("township_city")} rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Select township"
                                                                }
                                                            ]}>
                                                                <NJVSelect disabled={!is_data_editable} bgcolor={Theme.colors.secondary} placeholder={i18next.t("township_city")} size="large" showSearch filterOption={this.filterOption} onChange={(event) => this.handleUserInput('townshipId', event)} options={
                                                                    townshipList && townshipList.map((township) => {
                                                                        return {
                                                                            value: township.id,
                                                                            label: township.name,
                                                                            type: 'township'
                                                                        }
                                                                    })
                                                                } />
                                                            </Form.Item>
                                                        </Col>
                                                        {
                                                            userInput.orderType === 2 ?
                                                                <Col span={24}>
                                                                    <Form.Item name="busGateId" label={i18next.t("bus_gate_name")} rules={[
                                                                        {
                                                                            required: true,
                                                                            message: i18next.t('err_msg_select_bus_gate')
                                                                        }
                                                                    ]}>
                                                                        <NJVSelect
                                                                            disabled={!is_data_editable}
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            bgcolor={Theme.colors.secondary}
                                                                            value={(userInput && userInput.busGateId) ?? userInput.busGateId}
                                                                            placeholder={i18next.t("bus_gate_name")}
                                                                            size="large"
                                                                            onChange={(event) => this.handleUserInput('busGateId', event)}
                                                                            loading={is_busgate_data_fetching}
                                                                            options={
                                                                                busGateList && busGateList.map((busGate) => {
                                                                                    return {
                                                                                        value: busGate.id,
                                                                                        label: busGate.busGateName,
                                                                                        type: 'busGate'
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                :
                                                                <></>
                                                        }

                                                        {
                                                            userInput.orderType === HOME_DELIVERY || userInput.orderType === 3 ?
                                                                <Col span={24}>
                                                                    <Form.Item name="address" label={i18next.t("address")} rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Enter address"
                                                                        }
                                                                    ]}>
                                                                        <NJVInput
                                                                            bgcolor={Theme.colors.secondary} disabled={!is_data_editable} size="large" istextareainput="true" showCount maxLength={180} />
                                                                    </Form.Item>
                                                                </Col>
                                                                :
                                                                <></>
                                                        }
                                                        <Col span={24}>
                                                            <Form.Item name="paymentOption" label={i18next.t("payment_option")} rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Choose payment type'
                                                                }
                                                            ]}>
                                                                <Radio.Group disabled={!is_data_editable}
                                                                    value={userInput.orderType === HOME_DELIVERY ? (userInput && userInput.paymentOption) ?? userInput.paymentOption : PAY_BY_SHIPPER}
                                                                    onChange={(event) => this.handleUserInput('paymentOption', event.target.value)} >
                                                                    <Radio
                                                                        value={1}
                                                                        disabled={userInput.orderType === HOME_DELIVERY || userInput.status === 'PENDING' ? false : true}
                                                                    >
                                                                        {i18next.t("collect_from_customer")}
                                                                    </Radio>
                                                                    <Radio
                                                                        value={2}>{i18next.t("pay_by_shipper")}
                                                                    </Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item label={i18next.t("parcel_value")}>
                                                                {data.parcelAmount ? `${data.parcelAmount} MMK` : `0 MMK`}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item label={i18next.t("delivery_fees")}>
                                                                {
                                                                    userInput.orderType === HOME_DELIVERY ?
                                                                        <>
                                                                            {
                                                                                is_fetching_price_script ?
                                                                                    <Spin
                                                                                        indicator={
                                                                                            <LoadingOutlined
                                                                                                style={{
                                                                                                    fontSize: 24,
                                                                                                }}
                                                                                                spin
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                    :
                                                                                    <>{userInput.deliveryCharges ? userInput.deliveryCharges : 0} MMK</>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {userInput.deliveryCharges ? userInput.deliveryCharges : 0} MMK
                                                                        </>
                                                                }
                                                            </Form.Item>
                                                        </Col>
                                                        {
                                                            is_data_editable ?
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <NJVButton type="primary" htmlType="submit" style={{ backgroundColor: Theme.colors.primary }} loading={is_voucher_confirming}>Confirm</NJVButton>
                                                                </Col>
                                                                :
                                                                <></>
                                                        }
                                                    </Row>
                                                </Form>
                                            </>
                                            :
                                            <></>
                                    }
                                </>
                        }
                    </Row>
                </Col >
            </Row >
        )
    }
}

export default compose(withRouter)(QuickVoucherCustomerConfirm)
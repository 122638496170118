import { ConfigProvider, Menu } from 'antd'
import Sider from 'antd/es/layout/Sider'
import React, { useEffect, useState } from 'react'
import { SIDE_MENU } from '../../core/constant'
import MenuItem from 'antd/es/menu/MenuItem'
import { Link } from 'react-router-dom'
import { GenerateSvgIconBaseOnName } from '../../core/nvm-utils'
import i18next from 'i18next'
import { currentSelectedMenu, updateSelectedMenu } from '../../redux/cookie-slice'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettlementNoti } from '../../redux/profile-slice'
import { ApiHandler } from '../../network/network-manager'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'

const AppSidebar = ({ collapsed }) => {

    const currentSelectedMenuIndex = useSelector(currentSelectedMenu)
    const [selected, setSelected] = useState(0)
    const [isExistUnread, setIsExistUnread] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        setSelected(currentSelectedMenuIndex)
        fetchExistNotifications();
    }, [currentSelectedMenuIndex])

    const fetchExistNotifications = async () => {
        await ApiHandler({ url: Api.isExist_notification, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                setIsExistUnread(response)
            }).catch(() => { })
    }

    const handleSelectMenu = (event) => {
        setSelected(event.key)
        dispatch(updateSelectedMenu(event.key))
    }

    const handleMenuClick = (route) => {
        if (route === '/v1/invoice') {
            dispatch(updateSettlementNoti(false));
        }
    }

    return (
        <Sider
            className='custom-side-bar'
            trigger={null} width={`${collapsed ? '60' : '246'}`}
            style={{
                height: "90vh",
                position: 'sticky',
                top: '70px',
                zIndex: 1,
                width: '100%',
                backgroundColor: '#f5f5f5'
            }}
        >
            <ConfigProvider
                theme={{
                    token: {
                        controlHeightLG: 50,
                        margin: 0
                    },
                    components: {
                        Menu: {
                            itemMarginInline: 0,
                            itemMarginBlock: 0
                        }
                    }
                }}
            >
                <Menu
                    style={{
                        background: '#f5f5f5',
                        border: 'none',
                    }}
                    onSelect={handleSelectMenu}
                    selectedKeys={[currentSelectedMenuIndex]}
                    mode='inline'
                >
                    {
                        SIDE_MENU.map((menu, index) => {
                            const currentIndex = '' + (index + 1)
                            return <MenuItem key={currentIndex} icon={GenerateSvgIconBaseOnName(menu.name, selected, currentIndex, isExistUnread)}>
                                <Link to={menu.route} onClick={() => handleMenuClick(menu.route)}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span className="sidebar-text">{i18next.t(menu.name)}</span>
                                    </div>
                                </Link>
                            </MenuItem>
                        })
                    }
                </Menu>
            </ConfigProvider>
        </Sider>
    )
}

export default AppSidebar
import { DeleteOutlined, EditOutlined, MenuOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Col, Dropdown, List, Modal, Row, Skeleton, Tag, message } from "antd";
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import CustomPath from "../../routes/custom-path";
import { NJVButton } from "../../components/core-component";
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';
import Lottie from "lottie-react";
import { SvgOrderDollyFilled, SvgTruckFilled } from "../../components/custom-svgIcon";

class LoctionList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loyal_customer_address: [],
            my_address: [],
            isDataFetching: false,
            deleteItem: null,
            currentKey: '1',
            page: 0,
            show_load_more: true,
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({
            isDataFetching: true
        })
        try {
            let current;
            switch (this.props.status) {
                case 'myLocation':
                    current = true
                    break;
                case 'royalCustomer':
                    current = false
                    break;
                default:
                    break;
            }
            const requestParams = {
                page: 0,
                isMyLocation: current
            }
            const response = await ApiHandler({url: Api.user_address_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response && response.content) {
                let listData = []
                response.content.forEach(address => {
                    listData.push(address)
                })
                this.setState({
                    data: listData,
                    isDataFetching: false,
                    isLast: response.last
                });
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    onDelete = (item) => {
        if (item.defaultLocation) {
            message.error(`${i18next.t("cannot_delete")}`)
            return
        }
        this.setState({
            openModal: true,
            deleteItem: item
        })
    }

    deleteConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiHandler({url: Api.user_address, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON,specificId: deleteItem.id})
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: null
        });
        this.fetchData()
    }

    onChange = (key) => {
        this.setState({
            currentKey: key
        }, () => this.fetchData());
    }

    loadMore = async () => {
        const { page } = this.state

        this.setState({
            isLoadMore: true
        })

        try {
            let current;
            switch (this.props.status) {
                case 'myLocation':
                    current = true
                    break;
                case 'royalCustomer':
                    current = false
                    break;
                default:
                    break;
            }
            const requestParams = {
                page: page + 1,
                isMyLocation: current
            }
            const response = await ApiHandler({url: Api.user_address_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response && response.content) {
                let listData = []
                response.content.forEach(address => {
                    listData.push(address)
                })
                this.setState((prevState) => (
                    {
                        data: [...prevState.data, ...listData],
                        isLast: response.last
                    }
                ));
            }
        } catch (error) {
        }
        this.setState({
            isLoadMore: false,
            page: page + 1
        })
    }

    render() {
        const { openModal, data, isDataFetching, isLoadMore, isLast } = this.state
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteConfirm()}
                    onCancel={() => this.setState({ openModal: false })}
                    okText={i18next.t("confirm")}
                    cancelText={i18next.t("cancel")}
                >
                    <p>{i18next.t("are_you_sure_to_delete")}</p>
                </Modal>
                {
                    isDataFetching ? <Skeleton /> :
                        <>
                            {
                                data?.length !== 0 ?
                                    <>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={data}
                                            renderItem={(item, index) => (
                                                <List.Item>
                                                    <Row style={{ width: '100%' }}>
                                                        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <div style={{ fontSize: 18, fontWeight: '500' }}>{item.name} - {item?.phone}</div>
                                                            <Dropdown
                                                                trigger={['click']}
                                                                placement="bottomRight"
                                                                menu={{
                                                                    items: [
                                                                        {
                                                                            label: <Link to={`${CustomPath.user_address_update}/${item.id}`} state={this.props.status}>
                                                                                <EditOutlined style={{ marginRight: 7 }} />{i18next.t("edit")}
                                                                            </Link>,
                                                                            key: '0',
                                                                        },
                                                                        {
                                                                            label: <span onClick={() => { this.onDelete(item) }}><DeleteOutlined style={{ marginRight: 7 }} />{i18next.t("delete")}</span>,
                                                                            key: '1',
                                                                        }
                                                                    ]
                                                                }}
                                                            >
                                                                <div style={{ width: 30, height: 30, background: '#1677ff', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                                                    <MenuOutlined style={{ color: 'white' }} />
                                                                </div>
                                                            </Dropdown>
                                                        </Col>
                                                        <Col span={24}>
                                                            <div style={{ fontSize: 14, fontWeight: '500', marginRight: 10 }}>{item.address1}, {item.township.name}  ,{item.township.division.name}</div>
                                                        </Col>
                                                        <Col span={24} style={{ marginTop: 10 }}>
                                                            <div style={{ display: 'flex' }}>
                                                                {
                                                                    item.township && item.township.active && item.township.division && item.township.division.active && item?.township?.enableHomeDelivery && item?.township?.division?.enableHomeDelivery ?
                                                                        <></>
                                                                        :
                                                                        <Tag style={{ display: 'flex', alignItems: 'center', borderRadius: 20, marginRight: 5 }} color="#F5222D">
                                                                            <SvgOrderDollyFilled width={15} height={15} color={'white'} />
                                                                            <span style={{ color: 'white', fontWeight: 500, marginLeft: 5 }}>Service Unavailable</span>
                                                                        </Tag>
                                                                }

                                                                {
                                                                    item.township && item.township.active && item.township.division && item.township.division.active && item?.township?.enableBusGatePostOfficeService ?
                                                                        <>
                                                                        </>
                                                                        :
                                                                        <Tag style={{ display: 'flex', alignItems: 'center', borderRadius: 20, marginRight: 5 }} color="#F5222D">
                                                                            <SvgTruckFilled width={15} height={15} color={'white'} />
                                                                            <span style={{ color: 'white', fontWeight: 500, marginLeft: 5 }}>Service Unavailable</span>
                                                                        </Tag>
                                                                }

                                                            </div>



                                                        </Col>
                                                        <Col span={24} style={{ marginTop: 10 }}>
                                                            {/* {
                                                                item.township && item.township.active && item.township.division && item.township.division.active ?
                                                                    <></>
                                                                    :
                                                                    <Tag style={{ borderRadius: 20, marginRight: 5 }} color="#F5222D">Currently Unavailable</Tag>
                                                            } */}
                                                            {
                                                                item.defaultLocation ? <Tag style={{ marginRight: 0, borderRadius: 20 }} color="#1677ff">{i18next.t("default_location")}</Tag> : <></>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        />
                                        {
                                            !isLast ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <NJVButton type="primary" onClick={() => this.loadMore()} loading={isLoadMore}>
                                                        Load More
                                                    </NJVButton>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </> :
                                    <Row>
                                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                            <Lottie
                                                style={{ height: '200px' }}
                                                animationData={NoDataFoundAnimation}
                                                loop={true}
                                                autoplay={true}
                                            />
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                            <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                                                "No list yet"
                                            </div>

                                        </Col>
                                    </Row>
                            }
                        </>
                }

            </>
        )
    }
}

export default compose(withRouter)(LoctionList)
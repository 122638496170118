import { PlusOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Alert, Col, Flex, Form, Image, Modal, Radio, Row, Select, Skeleton, Steps, Tag, Tooltip, Typography, Upload, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import Lottie from "lottie-react";
import React from "react";
import ReactToPrint from "react-to-print";
import { NJVBackButtonWithIcon, NJVButton, NJVCard, NJVInput, NJVSelect, NJVSpacer } from "../../components/core-component";
import { SvgArrowLeftFilled, SvgCheck, SvgCustomerServiceFill, SvgOrderIncomplete } from "../../components/custom-svgIcon";
import Theme, { Colors } from "../../components/theme";
import Api from "../../network/api";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

import { Link } from "react-router-dom";
import IssueCloseAnimation from '../../animate-lottie/lottie-issue-close.json';
import IssuePendingAnimation from '../../animate-lottie/lottie-issue-pending.json';
import { default as IssueCompleteAnimation, default as SuccessAnimation } from '../../animate-lottie/lottie-success.json';
import DeliveryInsurance from '../../asset/delivery-insurance.png';
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import AirWayBillSlip from "../awb/airway-bill-slip";

const containerW = '3in'
const containerH = '4.2in'

const PENDING_PICK_UP = 'PENDING_PICK_UP'
// const SUCCESSFUL_PICKUP = 'SUCCESSFUL_PICKUP'
// const SUCCESSFUL_DELIVERY = 'SUCCESSFUL_DELIVERY'
// const ARRIVED_AT_SORTING_HUB = 'ARRIVED_AT_SORTING_HUB'
// const EN_ROUTE_TO_SORTING_HUB = 'EN_ROUTE_TO_SORTING_HUB'
// const ON_VEHICLE_FOR_DELIVERY = 'ON_VEHICLE_FOR_DELIVERY'
// const COMPLETED = 'COMPLETED'
// const PENDING_RESCHEDULE = 'PENDING_RESCHEDULE'
// const RETURNED_TO_SENDER = 'RETURNED_TO_SENDER'
const CANCELLED = 'CANCELLED'

// const eventMap = {
//     'PENDING_PICK_UP': 0,
//     'SUCCESSFUL_PICKUP': 1,
//     'ARRIVED_AT_SORTING_HUB': 2,
//     'EN_ROUTE_TO_SORTING_HUB': 3,
//     'ON_VEHICLE_FOR_DELIVERY': 4,
//     'SUCCESSFUL_DELIVERY': 5,
//     'COMPLETED': 6,
//     'PENDING_RESCHEDULE': 7,
//     'RETURNED_TO_SENDER': 8,
//     'CANCELLED': 9
// }

const { Option } = Select

const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)

}

const NJVValue = ({ value }) => {
    return (
        <>
            <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
            <br />
        </>)
}

const StepTitileStyle = ({ title, date, event }) => {
    return (<>
        <>
            {
                date ?
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                            <span>{title}</span>
                            <div><Tag>{date}</Tag></div>
                        </div>

                        {
                            event.deliveryException ?
                                <>
                                    {
                                        event.deliveryException.failure_reason ?
                                            <div style={{ color: 'red' }}>Fail Reason : {event.deliveryException.failure_reason}</div>
                                            :
                                            <></>
                                    }


                                    {
                                        event.deliveryException.proof && event.deliveryException.proof.image_uris ?
                                            <div style={{ display: 'flex' }}>
                                                {
                                                    event.deliveryException.proof.image_uris.map(image =>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                        }
                        {
                            event.deliveryInformation ?

                                <div style={{ display: 'flex' }}>
                                    {
                                        event.deliveryInformation.proof && event.deliveryInformation.proof.image_uris ?
                                            <>
                                                {
                                                    event.deliveryInformation.proof.image_uris.map(image =>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                        </div>
                                                    )
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        event.deliveryInformation.proof && event.deliveryInformation.proof.signature_uri ?
                                            <div style={{ marginLeft: 5 }}>
                                                <Image src={event.deliveryInformation.proof.signature_uri} width={50} height={50} style={{ borderRadius: 6, backgroundColor: 'white' }} />
                                            </div>
                                            :
                                            <></>

                                    }
                                </div>
                                :
                                <></>
                        }
                        {
                            event.pickupException ?
                                <>
                                    {
                                        event.pickupException.failure_reason ?
                                            <div style={{ color: 'red' }}>Fail Reason : {event.pickupException.failure_reason}</div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                        }
                    </div >
                    :
                    <>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}><span>{title}</span></div>
                    </>
            }
        </>
    </>)
}

const category = [{ label: "Request Support", value: "Request Support", key: 'request_support' }, { label: "Complaint Issue", value: "Complaint Issue", key: 'complaint_issue' }]
const subCategory = {
    "Request Support": [
        { label: "Return to Sender", value: "Return to Sender", key: 'ticket_return_to_sender' },
        { label: "Bus Gate Pickup", value: "Bus Gate Pickup", key: 'ticket_bus_gate_pickup' },
        { label: "Change Phone Number", value: "Change Phone Number", key: 'ticket_change_phone_number' },
        { label: "Change Address", value: "Change Address", key: 'ticket_change_address' },
        { label: "Change COD Amount", value: "Change COD Amount", key: 'ticket_change_cod_amount' },
        { label: "Change Delivery Date", value: "Change Delivery Date", key: 'ticket_delivery_date' },
        { label: "Pending Credit Payment", value: "Pending Credit Payment", key: 'ticket_credit_payment' }
    ],
    "Complaint Issue": [
        { label: "Remittance Issue", value: "Remittance Issue", key: 'ticket_remittance_issue' },
        { label: "Parcel Loss", value: "Parcel Loss", key: 'ticket_parcel_loss' },
        { label: "Parcel Damage", value: "Parcel Damage", key: 'ticket_parcel_damage' },
        { label: "Pick Up Issue", value: "Pick Up Issue", key: 'ticket_pickup_issue' },
        { label: "Delivery Lead Time Issue", value: "Delivery Lead Time Issue", key: 'ticket_delivery_lead_time_issue' },
        { label: "Bus Gate Vouchers", value: "Bus Gate Vouchers", key: 'ticket_bus_gate_vouchers' },
        { label: "KG/CM Issue", value: "KG/CM Issue", key: 'ticket_kg_cm_issue' },
        { label: "Service Attitude", value: "Service Attitude", key: 'ticket_service_attitude' }
    ]
}

class OrderDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            order_object: props.location.state?.data || {},
            data: {},
            orderEvent: [],
            currentStep: 0,
            orderSteps: [],
            sub_category_collection: [],
            fileList: [],
            claimFileList: [],
            previewOpen: false,
            previewImage: null,
            pending_reschedule: null,
            isDataFetching: false,
            viewPhoto: false,
            showInfoModal: false,
            showOrderCancelConfirmModal: false,
            isCancelling: false,
            showIssueProgressingModal: false,
            isFormSubmittedSuccess: false,
            is_submit_request: false,
            form_category: null,
            form_sub_category: null,
            form_reason: null,

            confirming_tracking_number: false,
            openClaimModal: {
                claimCreate: false, claimSuccess: false, viewItems: false
            },
            claimLoading: {
                requestClaim: false, viewItems: false
            }
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
        this.fetchOrderEvent()
    }

    fetchData = async () => {
        const { order_object } = this.state
        try {
            const requestParams = {
                trackingNumber: order_object.id
            }
            this.setState({
                isDataFetching: true
            })
            const response = await ApiHandler({ url: Api.order_get_by_tracking_number, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let data = response
                let fromAddress = data.fromAddress
                let toAddress = data.toAddress
                if (data && data.fromTownship) {
                    fromAddress = fromAddress + "," + data.fromTownship.name
                    if (data.fromTownship.division) {
                        fromAddress = fromAddress + "," + data.fromTownship.division.name
                    }
                }
                if (data && data.toTownship) {
                    toAddress = toAddress + "," + data.toTownship.name
                    if (data.toTownship.division) {
                        toAddress = toAddress + "," + data.toTownship.division.name
                    }
                }
                data = { ...data, senderAddress: fromAddress, recipientAddress: toAddress }

                if (data.createdDate != null) {
                    const createdDate = dayjs(data.createdDate, 'YYYY-MM-DD HH:mm A');
                    if (createdDate) {
                        data = { ...data, 'createdDate': createdDate.format('YYYY-MMM-DD hh:mm A') }
                    }
                }

                this.setState({
                    data: data
                }, () => (data.insuranceOrder && data.orderStatus !== "CANCELLED") && this.fetchInsuranceDetail())
            }
        } catch (error) {

        }
        this.setState({
            isDataFetching: false,
        })
    }

    fetchOrderEvent = async () => {
        await ApiHandler({ url: Api.order_event, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: { trackingNumber: this.state.order_object.id } })
            .then((response) => {

                let currentStep = 0
                let status = PENDING_PICK_UP
                let timeMap = {}
                let steps = []
                let pending_reschedule = null

                response?.forEach((orderEvent, index) => {
                    status = orderEvent.orderStatus
                    let formatedEventTime = ''
                    if (orderEvent.timestamp) {
                        const eventTime = dayjs(orderEvent.timestamp, 'YYYY-MM-DD HH:mm:ss');
                        if (eventTime) {
                            timeMap[orderEvent.orderStatus] = eventTime.format('YYYY-MMM-DD hh:mm A')
                            formatedEventTime = eventTime.format('YYYY-MMM-DD hh:mm A')
                        }
                    }

                    if (status === CANCELLED) {
                        steps.push(
                            {
                                key: index + 1,
                                title: <StepTitileStyle title={orderEvent.status} date={formatedEventTime} event={orderEvent} />,
                                icon: <SvgOrderIncomplete width={25} height={25} color={'red'} />
                            }
                        )
                    } else {
                        steps.push(
                            {
                                key: index + 1,
                                title: <StepTitileStyle title={orderEvent.status} date={formatedEventTime} event={orderEvent} />,
                                icon: <SvgCheck width={25} height={25} color={'green'} />
                            }
                        )
                    }

                    if (orderEvent.orderStatus === 'PENDING_RESCHEDULE') {
                        pending_reschedule = orderEvent.comments
                    }

                    currentStep = index + 1
                })
                this.setState({
                    pending_reschedule,
                    orderEvent: response.orderEvents ? response.orderEvents : [],
                    currentStep: currentStep,
                    orderSteps: steps
                })
            }).catch(() => {

            })
    }

    fetchInsuranceDetail = async () => {

        this.setState({
            claimLoading: {
                viewItems: true
            }
        })

        const requestParams = {
            "trackingNumber": this.state.data.trackingNumber
        }
        await ApiHandler({ url: Api.insurance_items, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState((prev) => ({
                    data: {
                        ...prev.data,
                        claimStatus: response?.claimStatus,
                        insuranceItems: response?.insuranceItems
                    }
                }))
            }).catch((error) => { })
            .finally(() => {
                this.setState({
                    claimLoading: {
                        viewItems: false
                    }
                })
            })
    }

    cancelOrder = async (tracking_number) => {
        this.setState({
            isCancelling: true
        })
        try {
            await ApiHandler({ url: Api.order, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: tracking_number, curstomMessage: "Your order has been cancelled." })
        } catch (error) {
        }
        this.setState({
            showOrderCancelConfirmModal: false,
            isCancelling: false
        })

        this.fetchData()
    }

    onchangeCategory = (value) => {
        this.setState({
            form_sub_category: null,
            form_category: value,
            sub_category_collection: subCategory[value]
        })
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    handlePreview = async (file) => {

        if (!file.thumbUrl && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.thumbUrl || file.preview,
            previewOpen: true,
        });

    };

    handleChange = (key, { fileList }) => {
        if (key === 'support') {
            this.setState({
                fileList: fileList
            })
        } else if (key === 'claim') {
            this.setState({
                claimFileList: fileList
            })
        }
    }

    submitRequestSupport = async () => {
        const { form_category, form_reason, form_sub_category, fileList, data } = this.state
        if (form_category === null || form_category === '') {
            message.error("Please select category")
            return
        } else if (form_sub_category === null || form_sub_category === '') {
            message.error("Please select sub category")
            return
        } else if (form_reason === null || form_reason === '') {
            message.error("Please fill reason")
            return
        }
        if (form_sub_category === 'Bus Gate Pickup' && fileList[0] === undefined) {
            message.error("Please select photo")
            return
        }
        if (form_sub_category === 'Parcel Damage' && fileList[0] === undefined) {
            message.error("Please select photo")
            return
        }
        this.setState({ is_submit_request: true })

        let formData = new FormData();
        formData.append('trackingNumber', data.trackingNumber)
        formData.append('category', form_category)
        formData.append('subCategory', form_sub_category)
        formData.append('reason', form_reason)
        formData.append('type', 'NORMAL_ORDER')
        if (fileList && fileList[0] && fileList[0].originFileObj) {
            formData.append('imageFile', fileList[0].originFileObj)
        }
        await ApiHandler({ url: Api.order_issue, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, disableShowMessage: true })
            .then(response => {
                const orderIssue = response.data
                let orderDetail = {
                    ...orderIssue.order, issue: orderIssue
                }

                this.setState({
                    isFormSubmittedSuccess: true,
                    data: orderDetail,
                    is_submit_request: false,
                    form_category: null,
                    form_sub_category: null,
                    form_reason: null,
                    fileList: []
                })
            })
            .catch(error => { });
    }

    searchTrackingNumber = async () => {
        const { data } = this.state
        this.setState({ confirming_tracking_number: true })
        try {
            const requestParams = {
                trackingNumber: data.trackingNumber
            }
            const response = await ApiHandler({ url: Api.confirm_rom_order, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.props.navigate(
                    CustomPath.rom_order,
                    { state: { data: response } }
                );
            }
        } catch (error) {
            message.error("Can not request this time")
        }
        this.setState({ confirming_tracking_number: false })
    }

    submitClaimRequest = async (values) => {
        this.setState({
            claimLoading: {
                requestClaim: true
            }
        })
        let formData = new FormData();
        formData.append('trackingNumber', this.state.data.trackingNumber)
        formData.append('parcelCondition', values?.parcelCondition)
        formData.append('claimReason', values?.claimReason)
        if (values?.photo?.fileList[0]?.originFileObj) {
            formData.append('imageFile', values?.photo?.fileList[0]?.originFileObj)
        }
        if (values?.photo?.fileList[1]?.originFileObj) {
            formData.append('imageFile2', values?.photo?.fileList[1]?.originFileObj)
        }
        if (values?.photo?.fileList[2]?.originFileObj) {
            formData.append('imageFile3', values?.photo?.fileList[2]?.originFileObj)
        }
        await ApiHandler({ url: Api.insurance_claim, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            .then((response) => {
                this.setState({
                    openClaimModal: {
                        claimCreate: false,
                        claimSuccess: true
                    },
                    claimFileList: []
                })
            }).catch((error) => { })
            .finally(() => this.setState({
                claimLoading: {
                    requestClaim: false
                }
            }))
        this.formRef.current.resetFields();
    }

    handleInsuranceDetail = async () => {
        this.setState({
            openClaimModal: {
                viewItems: true
            }
        })
    }

    handleCancelClaim = () => {
        this.setState({
            openClaimModal: {
                claimCreate: false
            },
            claimFileList: []
        })

        this.formRef.current.resetFields();
    }

    render() {
        const { data, isDataFetching, currentStep, orderSteps, showInfoModal, fileList, showIssueProgressingModal, showOrderCancelConfirmModal, isCancelling, order_object, sub_category_collection, form_category, form_sub_category, form_reason, isFormSubmittedSuccess, is_submit_request, confirming_tracking_number, pending_reschedule, openClaimModal, claimFileList, previewImage, previewOpen, claimLoading } = this.state
                
        const customSizeStyles = {
            width: containerW,
            height: containerH,
        };
        return (
            <>
                <Modal
                    title={i18next.t("request_support")}
                    centered
                    open={showInfoModal}
                    onCancel={() => this.setState({ showInfoModal: false, form_category: null, form_sub_category: null, form_reason: null, fileList: [] })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    {
                        isFormSubmittedSuccess ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssuePendingAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_successfully_submitted")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ isFormSubmittedSuccess: false, showInfoModal: false })}>Close</NJVButton>
                            </div>
                            :
                            <>
                                <NJVLabel label={i18next.t("category")} />
                                <NJVSelect bgcolor={Theme.colors.secondary}
                                    value={form_category}
                                    onChange={(value) => this.onchangeCategory(value)}
                                    size="large"
                                    allowClear={true}
                                    style={{ width: '100%', marginBottom: 20, marginTop: 5 }}
                                    className='my-location-selector'
                                    placeholder={i18next.t("select_category")}
                                >
                                    {
                                        category && category.map((cat) => {
                                            return <Option key={cat.key} value={cat.value}>{i18next.t(cat.key)}</Option>
                                        })
                                    }
                                </NJVSelect>
                                <NJVLabel label={i18next.t("sub_category")} />
                                <NJVSelect bgcolor={Theme.colors.secondary}
                                    value={form_sub_category}
                                    onChange={(value) => this.setState({ form_sub_category: value })}
                                    size="large"
                                    allowClear={true}
                                    style={{ width: '100%', marginBottom: 20, marginTop: 5 }}
                                    placeholder={i18next.t("select_sub_category")}
                                >
                                    {
                                        sub_category_collection && sub_category_collection.map((subCategory) => {
                                            return <Option value={subCategory.value}>{i18next.t(subCategory.key)}</Option>
                                        })
                                    }
                                </NJVSelect>
                                <NJVLabel label={i18next.t("reason")} />
                                <NJVInput value={form_reason} bgcolor={Theme.colors.secondary} onChange={(e) => this.setState({ form_reason: e.target.value })} placeholder={i18next.t("enter_reason")} style={{ marginBottom: 20, marginTop: 5 }}
                                    showCount
                                    maxLength={200}
                                    istextareainput="true" />
                                <>
                                    <NJVLabel label={i18next.t("Photo")} />
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={this.handlePreview}
                                        onChange={(list) => this.handleChange('support', list)}
                                        beforeUpload={() => false}
                                    >
                                        {fileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}

                                    </Upload>
                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{
                                                display: 'none',
                                            }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => this.setState({ previewOpen: visible }),
                                                afterOpenChange: (visible) => !visible && this.setState({ previewOpen: '' }),
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </>
                                <div style={{ textAlign: 'right' }}>
                                    <NJVButton type="primary" onClick={() => this.submitRequestSupport()} style={{ backgroundColor: Theme.colors.primary }} loading={is_submit_request}>{i18next.t("request")}</NJVButton>
                                </div>
                            </>
                    }
                </Modal>
                <Modal
                    title={"Confirmation of Order Cancellation"}
                    centered
                    open={showOrderCancelConfirmModal}
                    onCancel={() => this.setState({ showOrderCancelConfirmModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <p>{i18next.t("confirm_cancel")}</p>
                    <NJVButton type='primary' loading={isCancelling} onClick={() => this.cancelOrder(data.trackingNumber)}>Cancel Now</NJVButton>
                </Modal>

                <Modal
                    title={"Issue Status"}
                    centered
                    open={showIssueProgressingModal}
                    onCancel={() => this.setState({ showOrderCancelConfirmModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    {
                        data && data.issue && data.issue.status === 'PENDING' ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssuePendingAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_processing")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ showIssueProgressingModal: false })}>Close</NJVButton>
                            </div>
                            :
                            <></>
                    }
                    {
                        data && data.issue && data.issue.status === 'CLOSED' ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssueCloseAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_closed")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ showIssueProgressingModal: false })}>Close</NJVButton>
                            </div>

                            :
                            <></>
                    }
                    {
                        data && data.issue && data.issue.status === 'COMPLETE' ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssueCompleteAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_resolve")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ showIssueProgressingModal: false })}>Close</NJVButton>
                            </div>
                            :
                            <></>
                    }
                </Modal>
                <Modal
                    title={"Confirm Insurance Claim"}
                    centered
                    open={openClaimModal.claimCreate}
                    onCancel={() => this.handleCancelClaim()}
                    footer={null}
                    closeIcon={null}
                >
                    <Form ref={this.formRef} layout="vertical" onFinish={this.submitClaimRequest}>
                        <Form.Item name="parcelCondition" label="Choose Parcel Condition" rules={[{ required: true, message: 'required **' }]}>
                            <Radio.Group>
                                <Radio value="Total Loss">{i18next.t('total_loss')}</Radio>
                                <Radio value="Partial Loss">{i18next.t('partial_loss')}</Radio>
                                <Radio value="Total Damage">{i18next.t('total_damage')}</Radio>
                                <Radio value="Partial Damage">{i18next.t('partial_damage')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="photo" label="Photo">
                            <Upload
                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                listType="picture-card"
                                fileList={claimFileList}
                                onPreview={this.handlePreview}
                                onChange={(list) => this.handleChange('claim', list)}
                                beforeUpload={() => false}
                            >
                                {claimFileList.length >= 3 ? null :
                                    <div>
                                        <PlusOutlined />
                                        <div
                                            style={{
                                                marginTop: 8,
                                            }}
                                        >
                                            Upload
                                        </div>
                                    </div>}
                            </Upload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{
                                        display: 'none',
                                    }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => this.setState({ previewOpen: visible }),
                                        afterOpenChange: (visible) => !visible && this.setState({ previewOpen: '' }),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </Form.Item>
                        <Form.Item name="claimReason" label="Claim Reason" rules={[{ required: true, message: 'required **' }]}>
                            <NJVInput value={form_reason} bgcolor={Theme.colors.secondary} placeholder={i18next.t("enter_reason")} style={{ marginBottom: 20, marginTop: 5 }}
                                showCount
                                maxLength={200}
                                istextareainput="true" />
                        </Form.Item>
                        <Flex justify="end" gap={15}>
                            <NJVButton onClick={() => this.handleCancelClaim()}>Cancel</NJVButton>
                            <NJVButton type="primary" htmlType="Submit" style={{ backgroundColor: Theme.colors.primary }} loading={claimLoading.requestClaim}>{i18next.t("Confirm")}</NJVButton>
                        </Flex>
                    </Form>
                </Modal>
                <Modal
                    width={350}
                    title={null}
                    centered
                    open={openClaimModal.claimSuccess}
                    onOk={() => this.setState({
                        openClaimModal: {
                            claimSuccess: false
                        }
                    })}
                    footer={null}
                    closeIcon={false}
                    maskClosable={false}
                    onCancel={() => this.setState({
                        openClaimModal: {
                            claimSuccess: false
                        }
                    })}

                >
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div style={{ width: '100%', textAlign: 'center', marginBottom: 20 }}>
                            <Lottie
                                style={{ height: '150px' }}
                                animationData={SuccessAnimation}
                                loop={false}
                                autoplay={true}
                            />
                            <div style={{ fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_success_claim")}</div>
                        </div>
                        <NJVButton shape='round' type='primary' onClick={() => {
                            this.setState({
                                openClaimModal: {
                                    claimSuccess: false
                                }
                            })
                            this.fetchInsuranceDetail()
                        }}>
                            Close
                        </NJVButton>
                    </div>
                </Modal >
                <Modal
                    title={"Insurance Item Detail"}
                    centered
                    open={openClaimModal.viewItems}
                    onCancel={() => this.setState({
                        openClaimModal: {
                            viewItems: false
                        }
                    })}
                    footer={null}
                    closeIcon={null}
                >
                    {
                        claimLoading.viewItems ? <Skeleton /> : (
                            <>
                                <Row
                                    style={{ padding: 10, borderRadius: 8, backgroundColor: '#f1f1f1', marginBottom: 0.7, textAlign: 'center' }}>
                                    <Col span={2}>
                                        <span>No.</span>
                                    </Col>
                                    <Col span={14}>
                                        <span>Product Name</span>
                                    </Col>
                                    <Col span={3}>
                                        <span>Price</span>
                                    </Col>
                                    <Col span={5}>Quantity</Col>

                                </Row>
                                <>
                                    {
                                        data?.insuranceItems?.map((product, index) => {
                                            return (
                                                <Row
                                                    key={index}
                                                    style={{ padding: 10, borderRadius: 8, backgroundColor: '#f1f1f1', marginBottom: 1, textAlign: 'center' }}>
                                                    <Col span={2}>
                                                        <span>{index + 1}</span>
                                                    </Col>
                                                    <Col span={14}>
                                                        <span>{product.name}</span>
                                                    </Col>
                                                    <Col span={3}>
                                                        <span>{product.price}</span>
                                                    </Col>
                                                    <Col span={5}>
                                                        <span>{product.quantity}</span>
                                                    </Col>
                                                </Row>

                                            )
                                        })
                                    }
                                </>
                            </>
                        )
                    }
                </Modal>

                <Row style={{ marginBottom: 200, paddingBottom: 200 }}>
                    <Col lg={6} md={6} xs={1} sm={1} />
                    <Col lg={12} md={12} xs={22} sm={22}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    order_object.isFromOrderHistory ?
                                        <Link to={order_object.route} relative="path">
                                            <NJVBackButtonWithIcon />
                                        </Link>
                                        :
                                        <Link to={CustomPath.notification} state={{ route: order_object.notificationType}}>
                                            <NJVBackButtonWithIcon />
                                        </Link>

                                }

                                <h2>{i18next.t("parcel_detail")}</h2>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <>
                                    {
                                        data && data !== undefined ?
                                            <>
                                                {
                                                    data.orderStatus === 'CANCEL' ?
                                                        <></>
                                                        :
                                                        <NJVButton type="primary" size='large' shape="square" onClick={() => {
                                                            this.setState({ showInfoModal: true })
                                                        }}>
                                                            <SvgCustomerServiceFill width={20} height={20} color={'#fff'} />
                                                        </NJVButton>
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                </>
                                {/* {
                                    order_object.isFromOrderHistory ?
                                        <Link to={order_object.route} relative="path">
                                            <NJVButton type="primary">{i18next.t('back')}</NJVButton>
                                        </Link>
                                        :
                                        <NJVButton type="primary" onClick={() => this.props.navigate(CustomPath.notification)}>{i18next.t('back')}</NJVButton>
                                } */}

                            </Col>
                        </Row>
                        <NJVSpacer height={20} />
                        {
                            isDataFetching ?
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Skeleton active />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    {
                                        data ?
                                            <div>
                                                {
                                                    data.orderStatus === 'CANCELLED' ?
                                                        <>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Alert style={{ backgroundColor: 'red', fontSize: 13, fontWeight: '600', color: 'white' }} message={i18next.t("parcel_canceled_lb")} type="error" />
                                                                </Col>
                                                            </Row>
                                                            <NJVSpacer height={20} />
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                                <Row>
                                                    <Col span={24}>
                                                        <NJVCard>
                                                            <NJVLabel label={i18next.t("tracking_number")} />
                                                            <NJVValue value={data.trackingNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.fromAddress && data.fromTownship ?
                                                                    <>
                                                                        <NJVLabel label={i18next.t("pickup_address")} />
                                                                        <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.division.name} `} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </NJVCard>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <NJVCard>
                                                            <NJVLabel label={i18next.t("receiver_name")} />
                                                            <NJVValue value={`${data.recipientName}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("receiver_phone_number")} />
                                                            <NJVValue value={data.recipientPhoneNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.toAddress && data.toTownship ?
                                                                    <>
                                                                        <NJVLabel label={i18next.t("receiver_address")} />
                                                                        <NJVValue value={`${data.toAddress}, ${data.toTownship.name}, ${data.toTownship.division.name}`} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </NJVCard>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <NJVCard>
                                                            <NJVSpacer height={30} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("pickup_time")} />
                                                            <NJVValue value={`${data.pickupDate}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("order_type")} />
                                                            <NJVValue value={`${data.orderType}`} />
                                                            {
                                                                data.paymentOption ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label={i18next.t("payment_option")} />
                                                                        <NJVValue value={`${data.paymentOption}`} />
                                                                    </>
                                                                    :
                                                                    <></>

                                                            }
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Note" />
                                                            {
                                                                data.note ?
                                                                    <NJVValue value={`${data.note}`} />
                                                                    :
                                                                    <span style={{
                                                                        color: 'lightgray',
                                                                        fontStyle: 'italic',
                                                                        fontSize: 16,
                                                                        fontWeight: '500'
                                                                    }}  >No note here</span>
                                                            }
                                                            <br />

                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("cod")} />
                                                            {
                                                                data.codAmount ?
                                                                    <NJVValue value={`${data.codAmount} MMK`} />
                                                                    :
                                                                    <NJVValue value={`0 MMK`} />
                                                            }

                                                            <NJVSpacer height={30} />

                                                            {
                                                                data && data.updateDeliveryCharges ?
                                                                    <>
                                                                        <NJVLabel label="Updated Delivery Charges" />
                                                                        <NJVValue value={`${data.updateDeliveryCharges}`} />
                                                                        <NJVSpacer height={30} />
                                                                    </> :
                                                                    <></>
                                                            }
                                                            {
                                                                data && data.updateCodAmount ?
                                                                    <>
                                                                        <NJVLabel label="Updated Cod Amount" />
                                                                        <NJVValue value={`${data.updateCodAmount}`} />
                                                                        <NJVSpacer height={30} />
                                                                    </> :
                                                                    <></>
                                                            }
                                                            <NJVLabel label={i18next.t("status")} />
                                                            <NJVSpacer height={10} />
                                                            <Steps
                                                                className="step-full-width-title"
                                                                size="small"
                                                                current={currentStep}
                                                                direction="vertical"
                                                                items={orderSteps}
                                                            />
                                                            {
                                                                pending_reschedule ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label={i18next.t("fail_reason")} />
                                                                        <NJVValue value={pending_reschedule} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            {
                                                                data.insuranceOrder && data.orderStatus !== "CANCELLED" ?
                                                                    <>
                                                                        <NJVLabel label={i18next.t("Note")} />
                                                                        <NJVSpacer height={10} />
                                                                        <Row>
                                                                            <Col lg={18} md={20} sm={24} xs={24}>
                                                                                <NJVCard cardBgColor={Theme.colors.white}>
                                                                                    <Flex vertical gap={20}>
                                                                                        <Flex justify="space-evenly" align="center" gap={5}>
                                                                                            <Image src={DeliveryInsurance} alt="insurance photo" preview={false} width={50} height={50} />
                                                                                            <Typography.Title style={{ margin: 0 }} level={5}>{i18next.t('insurance_label')}</Typography.Title>
                                                                                        </Flex>
                                                                                        <Flex justify="space-evenly">
                                                                                            <Tooltip title={`${data.claimStatus === "PENDING" ? i18next.t("insurance_pending_warnning") : data.claimStatus === "REQUESTED" ? i18next.t("insurance_requested_warnning") : ''}`}>
                                                                                                <NJVButton
                                                                                                    type="primary"
                                                                                                    style={{ backgroundColor: data.claimStatus === "PENDING" || data.claimStatus === "REQUESTED" ? '#f5f5f5' : Theme.colors.primary }} onClick={() => this.setState({
                                                                                                        openClaimModal: {
                                                                                                            claimCreate: true
                                                                                                        }
                                                                                                    })}
                                                                                                    disabled={data.claimStatus === "PENDING" || data.claimStatus === "REQUESTED"}
                                                                                                >{i18next.t("insurance_claim")}
                                                                                                </NJVButton>
                                                                                            </Tooltip>
                                                                                            <NJVButton style={{ backgroundColor: Theme.colors.card_bg_color }} onClick={this.handleInsuranceDetail}>{i18next.t("claim_detail")}</NJVButton>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                </NJVCard>
                                                                            </Col>
                                                                            <Col lg={3} md={2} sm={0} xs={0} />
                                                                        </Row>
                                                                    </> : <></>
                                                            }
                                                            <NJVSpacer height={30} />
                                                            <div style={{ display: 'flex' }}>
                                                                <di style={{ marginRight: 10 }}>
                                                                    <NJVLabel label="Product Image" />
                                                                    {
                                                                        data.productImageUrl ?

                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.productImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }
                                                                </di>
                                                                <div>
                                                                    <NJVLabel label="Package Image" />
                                                                    {
                                                                        data.packageImageUrl ?
                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.productImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }


                                                                </div>
                                                            </div>
                                                            {/* <br />
                                                            {
                                                                data.orderType === 'Bus Gate' ?
                                                                    <>
                                                                        <NJVLabel label="Bus Gate Voucher" />
                                                                        {
                                                                            data.busGateVoucherImagePath ?
                                                                                <Image
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        borderRadius: 9,
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => this.setState({ viewPhoto: true })}
                                                                                    src={data.busGateVoucherImagePath}
                                                                                    // preview={false}
                                                                                    width={200}
                                                                                />
                                                                                :
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        borderRadius: 9,
                                                                                        cursor: 'pointer',
                                                                                        padding: 10,
                                                                                        backgroundColor: '#f1f1f1',
                                                                                        border: '1px solid #f1f1f1',
                                                                                        width: 200,
                                                                                        height: 200,
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                >
                                                                                    <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                                </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <></>
                                                            } */}


                                                        </NJVCard>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                                                        {/* <div style={{ height: 20 }}> */}

                                                        <div
                                                            key={2}
                                                            ref={(el) => this.refComponent = el}
                                                            // style={customSizeStyles}
                                                            >
                                                            <AirWayBillSlip
                                                                key={1}
                                                                senderName={data.senderName}
                                                                senderPhone={data.senderPhoneNumber}
                                                                senderAddress={data.senderAddress}
                                                                receiverName={data.recipientName}
                                                                receiverPhone={data.recipientPhoneNumber}
                                                                receiverAddress={data.recipientAddress}
                                                                trackId={data.trackingNumber}
                                                                createDate={data.createdDate}
                                                                parcelAmount={data.parcelAmount}
                                                                deliveryCharges={data.deliveryCharges}
                                                                cod={data.codAmount}
                                                                note={data.note}
                                                                sortingCode={data.sortingCode}
                                                            />
                                                        </div>

                                                        {/* </div> */}
                                                    </Col>
                                                    <Col span={24}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <ReactToPrint
                                                                trigger={() => <NJVButton type="primary" style={{ backgroundColor: Colors.primary, width: 150 }}>Print</NJVButton>}
                                                                content={() => this.refComponent}
                                                            />
                                                            <br />

                                                            {
                                                                data.orderType === 'Home Delivery' && (data.orderStatus !== 'DELIVERED' || data.orderStatus !== 'CANCELED') ?
                                                                    <>
                                                                        <NJVSpacer height={50} />
                                                                        <NJVButton type="text" onClick={() => this.searchTrackingNumber()}>
                                                                            <span style={{ color: 'blue', fontWeight: '600' }} loading={confirming_tracking_number}>Request Parcel Exchange</span>
                                                                        </NJVButton>
                                                                    </>
                                                                    :
                                                                    <>
                                                                    </>
                                                            }
                                                            <br />
                                                            {
                                                                data.orderStatus === 'PENDING_PICK_UP' ?
                                                                    <>
                                                                        <NJVSpacer height={50} />
                                                                        <NJVButton type="text" onClick={() => this.setState({ showOrderCancelConfirmModal: true })}>
                                                                            <span style={{ color: 'red', fontWeight: '600' }}>Cancel Order</span>
                                                                        </NJVButton>
                                                                        <NJVSpacer height={50} />
                                                                        <br />
                                                                        <br />
                                                                    </>
                                                                    :
                                                                    <>
                                                                    </>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <NJVSpacer height={100} />
                                                </Row>

                                            </div>
                                            :
                                            <></>
                                    }
                                </>
                        }
                    </Col >

                </Row >
            </>
        )
    }

}

export default compose(withRouter)(OrderDetail)
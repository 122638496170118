const Theme = {
    colors: {
        primary: '#c2002f',
        secondary: '#f5f5f5',
        success: '#28a745',
        card_bg_color: '#f5f5f5',
        system_blue: '#4096ff',
        white: '#fff',
        light_gray: '#f5f5f5',
        light_gray_2: '#d3d3d3',
        red: '#FF0000',
        default: '#1677ff',
        default_bgcolor: 'rgb(241, 241, 244)'
    }
};

export const Colors = {
    darkGreen: '#006400',
    red: '#FF0000',
    primary: '#c2002f',

}

export default Theme;
import {
    CloseOutlined,
    RightCircleFilled
} from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Carousel, Col, Flex, Image, Modal, QRCode, Row, Skeleton, Typography } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TravelPng from '../../asset//travel.png'
import BeautyPng from '../../asset/beauty_fashion.png'
import FoodPng from '../../asset/foods.png'
import HotelPng from '../../asset/hotel.png'
import LifestylePng from '../../asset/lifestyle.png'
import EventPng from '../../asset/media_events.png'
import qrImage from '../../asset/ninja_qr_image.png'
import ThumbnailImg from '../../asset/ninja_thumbnail.jpg'
import qrCode from '../../asset/qr.svg'
import WellnessPng from '../../asset/wellness.png'
import { NJVButton, NJVCard } from '../../components/core-component'
import { SvgShinyStarFilled } from '../../components/custom-svgIcon'
import Theme from '../../components/theme'
import { GetListFromEnumObject } from '../../core/nvm-utils'
import { logClick, logView } from '../../firebase/analyticsUtils'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'
import moment from 'moment';
import { clearLoyalty, setBannerSlide, setLoyaltyInfo, setPopularShops, setShopTypes } from '../../redux/loyalty-slice'

const imageMap = {
    WELLNESS: WellnessPng,
    TRAVEL: TravelPng,
    HOTEL: HotelPng,
    MEDIA_AND_EVENT: EventPng,
    BEAUTY_AND_FASHION: BeautyPng,
    LIFESTYLE: LifestylePng,
    FOOD: FoodPng
};

const CACHE_DURATION_MINUTES = 180;

class LoyaltyPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loyaltyInfo: null,
            bannerItems: [],
            popularShops: [],
            shopTypes: [],
            openQR: false
        }
    }

    componentDidMount() {
        this.checkAndFetchData(this.props.loyaltyInfo, this.fetchLoyaltyInfo)
        this.checkAndFetchData(this.props.bannerSlide, this.fetchBannerSlide)
        this.checkAndFetchData(this.props.popularShops, this.fetchPopularShops)
        this.checkAndFetchData(this.props.shopTypes, this.fetchShopTypes)
        logView('loyalty_main_screen')
    }

    componentDidUpdate(prevProps) {

        if (prevProps.loyaltyInfo !== this.props.loyaltyInfo) {
            this.checkAndFetchData(this.props.loyaltyInfo, this.fetchLoyaltyInfo);
        }
        if (prevProps.bannerSlide !== this.props.bannerSlide) {
            this.checkAndFetchData(this.props.bannerSlide, this.fetchBannerSlide);
        }
        if (prevProps.popularShops !== this.props.popularShops) {
            this.checkAndFetchData(this.props.popularShops, this.fetchPopularShops);
        }

        if (prevProps.shopTypes !== this.props.shopTypes) {
            this.checkAndFetchData(this.props.shopTypes, this.fetchShopTypes);
        }
    }

    checkAndFetchData = (dataObj, fetchFunction) => {
        const lastFetched = dataObj?.lastFetched;

        if (!lastFetched || moment().diff(moment(lastFetched), 'minutes') > CACHE_DURATION_MINUTES) {
            fetchFunction()
        } else {
            this.setState({
                [dataObj.name]: dataObj.data
            })
        }
    }

    fetchLoyaltyInfo = async () => {
        this.setState({
            isFetchingLoyaltyInfo: true
        })
        await ApiHandler({ url: Api.loyalty_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    loyaltyInfo: response
                })
                this.props.dispatch(setLoyaltyInfo(response))
            })
            .catch((error) => {
                // handle error
            }).finally(() => {
                this.setState({
                    isFetchingLoyaltyInfo: false
                })
            })
    }

    fetchBannerSlide = async () => {
        this.setState({
            isFetchingBannerSlide: true
        })
        const requestParams = {
            'bannerType': 'LOYALTY_SHOP_DASHBOARD'
        }
        await ApiHandler({ url: Api.banner_slide, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    isFetchingBannerSlide: false
                })
                this.setState({
                    bannerItems: response
                })
                this.props.dispatch(setBannerSlide(response))
            })
            .catch((error) => {
                this.setState({
                    isFetchingImageData: false
                })
            })
    }

    fetchPopularShops = async () => {
        this.setState({
            isFetchingPopularShops: true
        })
        await ApiHandler({ url: Api.popular_shops, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    isFetchingPopularShops: false
                })

                this.setState({
                    popularShops: response
                })
                this.props.dispatch(setPopularShops(response))
            })
            .catch((error) => {
                this.setState({
                    isFetchingPopularShops: false
                })
            })
    }

    fetchShopTypes = async () => {
        this.setState({
            isFetchingShopTypes: true
        })
        const requestParams = {
            enumType: 'LOYALTY_SHOP_TYPE'
        }
        await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, requestParams })
            .then((response) => {
                this.setState({
                    shopTypes: GetListFromEnumObject(response)
                })
                this.props.dispatch(setShopTypes(GetListFromEnumObject(response)))
            }).catch((error) => { })
            .finally(() => {
                this.setState({
                    isFetchingShopTypes: false
                })
            })
    }

    render() {
        const { loyaltyInfo, isFetchingBannerSlide, isFetchingPopularShops, bannerItems, popularShops, shopTypes, isFetchingShopTypes, isFetchingLoyaltyInfo, openQR } = this.state

        const imageContainer = {
            border: '0.5px solid #ccc',
            width: 90,
            height: 90,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 12,
            boxShadow: '0 1px 6px -1px rgba(0, 0, 0, 0.15)'
        }

        return (
            <>
                <Modal
                    title={null}
                    footer={null}
                    centered
                    open={openQR}
                    closeIcon={false}
                    onOk={() => {
                        this.setState({
                            openQR: false
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            openQR: false
                        })
                    }}
                    okText="Logout"
                    cancelText="Cancel"
                    width={350}
                >
                    <div style={{ textAlign: 'center' }}>
                        <QRCode
                            errorLevel="H"
                            size={300}
                            iconSize={300 / 4}
                            value={this.props.shipperId}
                            icon={qrImage}
                            style={{
                                width: 'auto',
                                border: 0
                            }}
                        />
                        <h3>{this.props.shipperId}</h3>
                        <NJVButton type='primary' shape='circle' style={{ backgroundColor: 'red', borderRadius: '40 !important' }} onClick={() => {
                            this.setState({ openQR: false })
                        }}>
                            <CloseOutlined />
                        </NJVButton>
                    </div>
                </Modal>
                <Row>
                    <Col lg={4} md={2} sm={1} xs={1} />
                    <Col lg={16} md={20} sm={22} xs={22}>
                        <Typography.Title level={3} style={{ marginBottom: 20, marginTop: 0 }}>{i18next.t("ninja_rewards")}</Typography.Title>
                        <NJVCard cardBgColor={Theme.colors.primary} style={{ padding: 14 }}>
                            <Row>
                                {
                                    isFetchingLoyaltyInfo ? <Skeleton /> :
                                        <>
                                            <Col lg={18} md={18} xs={0} sm={0}>
                                                <Flex vertical gap={10}>
                                                    <Typography.Title level={3} style={{ color: '#fff', margin: 0 }}>ID No - {loyaltyInfo?.uniqueId}</Typography.Title>
                                                    <Typography.Text style={{ color: '#fff' }}><SvgShinyStarFilled width={20} height={20} color={'#FFD700'} /> <span style={{ fontSize: 20, fontWeight: 500 }}>{loyaltyInfo?.currentMonthLevel}</span></Typography.Text>
                                                    <Link to={CustomPath.point} state={{ info: loyaltyInfo }}>
                                                        <NJVButton primarycolor="#fff" size="large" style={{ width: 140 }}>
                                                            <span style={{ color: Theme.colors.primary }}>Point - {loyaltyInfo?.currentMonthPoint}</span>
                                                            <RightCircleFilled style={{ color: Theme.colors.primary, fontSize: 20 }} />
                                                        </NJVButton>
                                                    </Link>
                                                </Flex>
                                            </Col>
                                            <Col lg={0} md={0} xs={16} sm={16}>
                                                <Flex vertical gap={10}>
                                                    <Typography.Title level={4} style={{ color: '#fff', margin: 0 }}>ID No - {loyaltyInfo?.uniqueId}</Typography.Title>
                                                    <Typography.Text style={{ color: '#fff' }}><SvgShinyStarFilled width={20} height={20} color={'#FFD700'} /> <span style={{ fontSize: 16, fontWeight: 500 }}>{loyaltyInfo?.currentMonthLevel}</span></Typography.Text>
                                                    <Link to={CustomPath.point} state={{ info: loyaltyInfo }}>
                                                        <NJVButton primarycolor="#fff" style={{ width: 100 }}>
                                                            <span style={{ color: Theme.colors.primary }}>Point - {loyaltyInfo?.currentMonthPoint}</span>
                                                            <RightCircleFilled style={{ color: Theme.colors.primary, fontSize: 18 }} />
                                                        </NJVButton>
                                                    </Link>
                                                </Flex>
                                            </Col>
                                            <Col lg={6} md={6} xs={4} sm={4} style={{ textAlign: 'right' }}>
                                                <Image src={qrCode} preview={false} onClick={() => {
                                                    this.setState({ openQR: true })
                                                }} style={{ cursor: 'pointer', objectFit: 'cover', width: 100, height: 100 }} />
                                            </Col>
                                        </>
                                }
                            </Row>
                        </NJVCard>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                {isFetchingBannerSlide ? <Skeleton active /> :
                                    <>
                                        {
                                            bannerItems?.length > 0 &&
                                            <>
                                                <Typography.Title level={4} style={{ marginBottom: 20 }}>Current Deal</Typography.Title>
                                                <Carousel
                                                    style={{ textAlign: 'center' }}
                                                    height={200}
                                                    draggable={true}
                                                    arrows
                                                    autoplay
                                                    infinite
                                                >
                                                    {
                                                        bannerItems?.map((item) => (
                                                            <div key={item.id}
                                                            >
                                                                <a
                                                                    href={item.link && item.link !== '' ? item.link : ''}
                                                                    target={item.link && item.link !== '' ? '_blank' : '_self'}
                                                                    onClick={() => logClick('loyalty_main_screen', item.name)}
                                                                >
                                                                    <Image
                                                                        alt={item.name}
                                                                        style={{
                                                                            width: '100%',
                                                                            height: 200,
                                                                            borderRadius: 10,
                                                                            objectFit: 'cover',
                                                                            cursor: item.link && item.link !== '' ? 'pointer' : 'default'
                                                                        }}
                                                                        src={item.photoUrl}
                                                                        fallback={ThumbnailImg}
                                                                        preview={false}
                                                                    />
                                                                </a>
                                                            </div>
                                                        ))}
                                                </Carousel>
                                            </>
                                        }
                                    </>
                                }
                            </Col>
                            <Col span={24}>
                                <Typography.Title level={4} style={{ marginBottom: 20 }}>Most Popular Brands</Typography.Title>
                                {
                                    isFetchingPopularShops ? <Skeleton active /> :
                                        <Row gutter={[16, 16]}>
                                            <>
                                                {popularShops?.content?.slice(0, 7).map((shopData, index) => (
                                                    <Col
                                                        key={index}
                                                        lg={4}
                                                        md={6}
                                                        sm={8}
                                                        xs={8}
                                                        style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                                                    >
                                                        <Link to={`${CustomPath.loyalty_shop_detail}/${shopData.id}`} state={{ prevRoute: CustomPath.loyalty }}>
                                                            <div style={imageContainer}>
                                                                <Image
                                                                    src={shopData.shopProfileUrl}
                                                                    preview={false}
                                                                    width={80} height={80}
                                                                    style={{ cursor: 'pointer', objectFit: 'cover', borderRadius: 10 }}
                                                                />
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                ))}

                                                {popularShops?.content?.length > 7 && (
                                                    <Col
                                                        lg={4}
                                                        md={6}
                                                        sm={8}
                                                        xs={8}
                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                    >
                                                        <Link to={CustomPath.shops} state={{ shops: popularShops?.content }}>
                                                            <div style={imageContainer}>
                                                                <span style={{ color: Theme.colors.primary }}>See All</span>
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                )}
                                            </>
                                        </Row>
                                }
                            </Col>
                            <Col span={24}>
                                <Typography.Title level={4} style={{ marginBottom: 20 }}>Reward Categories</Typography.Title>
                                {
                                    isFetchingShopTypes ? <Skeleton /> :
                                        <Row gutter={[24, 24]}>
                                            {
                                                shopTypes?.map((type, index) => (
                                                    <Col key={index} lg={4} md={6} sm={8} xs={8}>
                                                        <Flex vertical justify='center' align='center' gap={5}>
                                                            <Link to={CustomPath.shops} state={{ shopType: type }}>
                                                                <Image width={80} src={imageMap[type.value]} preview={false} alt={type.label} style={{ cursor: 'pointer', objectFit: 'cover' }} />
                                                            </Link>
                                                            <span style={{ color: Theme.colors.primary, fontWeight: 'bold' }}>{type.label}</span>
                                                        </Flex>
                                                    </Col>
                                                ))}
                                        </Row>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(LoyaltyPage)
import { PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, DatePicker, Input, InputNumber, Modal, Select, Table } from 'antd';
import Theme from './theme';
import { SvgArrowLeftFilled } from './custom-svgIcon';
import Lottie from 'lottie-react';
import NoDataFoundAnimation from '../animate-lottie/lottie-not-data-found.json'

export const NJVSpacer = ({ height, width }) => {
    return (
        <div style={{ height: height, width: width, display: 'inline-block' }}></div>
    )
}

export const NJVAddButton = ({ handleClick }) => {
    return <Button
        type="primary"
        icon={<PlusCircleFilled />}
        shape="round"
        onClick={handleClick}
    >
        Create
    </Button>
}

export const NJVSearchButton = ({ onClick }) => {
    return <Button
        type="primary"
        icon={<SearchOutlined />}
        shape="round"
        onClick={onClick}
    >
        Search
    </Button>
}

export const NJVBackButtonWithIcon = () => {
    return <Button
        type="primary"
        shape="circle"
        size="large"
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
            backgroundColor: Theme.colors.light_gray_2
        }}
        icon={<SvgArrowLeftFilled width={22} height={22} color={'#000'} />}
    >

    </Button>
}

export const NJVButton = ({ children, ...buttonProps }) => {
    const { primarycolor } = buttonProps;

    return (
        <ConfigProvider theme={{
            token: {
                borderRadius: '7px',
                borderRadiusLG: '9px',
                colorPrimary: primarycolor ? primarycolor : '#1677ff',
                colorBgContainer: primarycolor ? primarycolor : '#fff'
            }
        }}>
            <Button
                shape='round'
                {...buttonProps}
            >
                {children}
            </Button>
        </ConfigProvider>
    )
}


export const NJVInput = ({ ...inputProps }) => {
    const { bgcolor, ispasswordinput, istextareainput, issearchinput } = inputProps;

    return (
        <ConfigProvider theme={{
            token: {
                colorBorder: '#fff',
                colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
                colorPrimaryHover: Theme.colors.default,
                colorPrimary: 'rgb(128, 128, 128)',
            },
            components: {
                Input: {
                    activeShadow: `0 0 0 1px ${Theme.colors.default}`,
                    borderRadius: 9,
                    borderRadiusLG: 9,
                },
                InputNumber: {
                    activeShadow: '0 0 0 0.5px rgb(128,128,128)',
                    borderRadius: 9,
                    borderRadiusLG: 9,
                },
                Search: {
                    activeShadow: '0 0 0 0.5px rgb(128,128,128)',
                    borderRadius: 9,
                    borderRadiusLG: 9,
                }
            },
        }}>
            {
                ispasswordinput ? <Input.Password {...inputProps} /> : inputProps.isNumberInput ? <InputNumber {...inputProps} /> :
                    istextareainput ? <Input.TextArea  {...inputProps} /> :
                        issearchinput ? <Input.Search {...inputProps} /> : <Input {...inputProps} />
            }
        </ConfigProvider>
    )
}


// old input style 
// export const NJVInput = ({ ...inputProps }) => {
//     const { bgcolor, ispasswordinput, istextareainput, issearchinput } = inputProps;

//     return (
//         <ConfigProvider theme={{
//             token: {
//                 colorBorder: '#fff',
//                 colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
//                 // colorPrimaryHover: '#f5f5f5',
//                 colorPrimary: 'rgb(128, 128, 128)',
//             },
//             components: {
//                 Input: {
//                     activeShadow: '0 0 0 0.5px rgb(128,128,128)',
//                     borderRadius: 9,
//                     borderRadiusLG: 9,
//                 },
//                 InputNumber: {
//                     activeShadow: '0 0 0 0.5px rgb(128,128,128)',
//                     borderRadius: 9,
//                     borderRadiusLG: 9,
//                 },
//                 Search: {
//                     activeShadow: '0 0 0 0.5px rgb(128,128,128)',
//                     borderRadius: 9,
//                     borderRadiusLG: 9,
//                 }
//             },
//         }}>
//             {
//                 ispasswordinput ? <Input.Password {...inputProps} /> : inputProps.isNumberInput ? <InputNumber {...inputProps} /> :
//                     istextareainput ? <Input.TextArea {...inputProps} /> :
//                         issearchinput ? <Input.Search {...inputProps} /> : <Input {...inputProps} />
//             }
//         </ConfigProvider>
//     )
// }

export const NJVSelect = ({ children, ...selectProps }) => {
    const { bgcolor } = selectProps;
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
                colorBorder: '#fff',
                colorPrimary: 'rgb(128, 128, 128)',
                colorPrimaryHover: '#fff',
                // controlHeightLG: '40px',
            },
            components: {
                Select: {
                    borderRadius: '9px',
                    optionPadding: '4px 12px',
                    optionSelectedBg: 'rgb(128, 128, 128)',
                    optionSelectedColor: '#fff',
                    optionHeight: 30
                }
            }
        }}>
            <Select {...selectProps} >{children}</Select>
        </ConfigProvider>
    )
}

export const NJVDatePicker = ({ ...datepickerProps }) => {
    const { bgcolor } = datepickerProps;
    return (
        <ConfigProvider theme={{
            token: {
                colorBorder: '#fff',
                colorPrimaryHover: '#fff',
                cellWidth: 36,
                colorPrimary: 'rgb(128, 128, 128)',
            },
            components: {
                DatePicker: {
                    colorBgContainer: `${bgcolor ? bgcolor : '#fff'}`,
                    activeShadow: '0 0 0 1px rgb(128, 128, 128)',
                    borderRadius: '9px',
                    activeBorderColor: '#fff'
                },
            }
        }}>
            <DatePicker {...datepickerProps} />
        </ConfigProvider>
    )
}

export const NJVCard = ({ children, ...cardProps }) => {
    const { cardPadding, cardBgColor, cardShadow, cardborder } = cardProps;
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: `${cardBgColor ? cardBgColor : Theme.colors.card_bg_color}`,
                boxShadowTertiary: `${cardShadow ? cardShadow : 'none'}`,
                padding: `${cardPadding ? cardPadding : '15px'}`,
                paddingLG: `${cardPadding ? cardPadding : '15px'}`,
            },
            components: {
                Card: {
                    headerHeight: 56
                }
            }
        }}>
            <Card {...cardProps} bordered={cardborder ? cardborder : false}>{children}</Card>
        </ConfigProvider>
    )
}

export const NJVTable = ({ ...tableProps }) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: '#fff'
            },
            components: {
                Table: {
                    headerBg: 'transparent',
                    // rowHoverBg: 'rgba(194, 0, 47, 0.1)',
                    // cellPaddingBlock: '5px',
                    borderColor: 'transparent'
                }
            }
        }}>
            <Table {...tableProps} />
        </ConfigProvider>
    )
}

export const NJVReportTable = ({ ...tableProps }) => {
    return (
        <ConfigProvider theme={{

            components: {
                Table: {
                    headerBg: 'transparent',
                    headerColor: '#fff',
                    // rowHoverBg: 'rgba(194, 0, 47, 0.1)',
                    cellPaddingBlock: '5px',
                    borderColor: 'transparent'
                }
            }
        }}>
            <Table className="custom-table" {...tableProps} />
        </ConfigProvider>
    )
}

export const NJVEmptyData = ({ ...props }) => {
    const { label } = props
    const message = label ? label : 'Oops! There’s nothing here right now. Try refreshing or come back later.'
    return <Card className="card-shadow" >
        <Lottie
            style={{ height: '200px' }}
            animationData={NoDataFoundAnimation}
            loop={true}
            autoplay={true}
        />
        <div style={{ textAlign: 'center', fontSize: 16, fontWeight: '600', color: 'gray' }}>
            {message}
        </div>

    </Card>
}

import { ConfigProvider, Layout } from 'antd';
import i18next from "i18next";
import cookies from "js-cookie";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './App.css';
import { updateCurrentRoute } from './redux/cookie-slice';

import AppContent from './components/layouts/app-content';
import AppHeader from './components/layouts/app-header';
import AppSidebar from './components/layouts/app-sidebar';
import AppSidebarV2 from './components/layouts/app-sidebar-v2';
import AppHeaderV2 from './components/layouts/app-header-v2';

function App() {

      const dispatch = useDispatch();
      const location = useLocation();

      const [currentLanguage, setCurrentLanguage] = useState(false)
      const [collapsed, setCollapsed] = useState(false);

      useEffect(() => {
            dispatch(updateCurrentRoute(location.pathname))
            const currentLanCode = cookies.get("i18next") || "en"
            setCurrentLanguage(currentLanCode)
            i18next.changeLanguage(currentLanCode)
            // document.body.style.zoom = "90%";
      }, [location.pathname, currentLanguage, dispatch])

      const handleChangeLanguage = (currentLang) => {
            setCurrentLanguage(currentLang)
      }

      return (
            <ConfigProvider theme={{
                  components: {
                        Layout: {
                              bodyBg: "#ffffff",
                        },
                        Menu: {
                              itemBorderRadius: 0,
                              itemSelectedBg: '#c2002f',
                        },
                        Button: {
                              primaryShadow: 'none',
                              defaultShadow: 'none',
                        },
                  }
            }}>
                  <Layout className="shipper_layout">
                        <AppHeader currentLanguage={currentLanguage} handleChangeLanguage={handleChangeLanguage}
                              collapsed={collapsed} setCollapsed={setCollapsed} />
                        <Layout>
                              <AppSidebar collapsed={collapsed} />
                              <AppContent />
                        </Layout>
                  </Layout>
            </ConfigProvider>
      );
}
export default App;


import {
  PlusCircleFilled
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Col, List, Modal, Row, Skeleton, Tag } from 'antd';
import dayjs from 'dayjs';
import i18next from 'i18next';
import Lottie from 'lottie-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';
import { NJVButton, NJVInput } from '../../components/core-component';
import { SvgEditFilled } from '../../components/custom-svgIcon';
import Theme from '../../components/theme';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import CustomPath from '../../routes/custom-path';
import VoucherDetailModal from './voucher-detail-modal';

class VoucherListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetchingData: false,
      page: 0,
      size: Constant.pageSize,
      isLastData: false,
      isOpenModal: false,
      selectedVoucherId: null
    }
  }

  componentDidMount() {
    this.fetchPendingVoucher()
    this.fetchCheckUserGroup()
  }

  fetchCheckUserGroup = async () => {
    this.setState({
      isFetchingUserGroup: true
    })
    await ApiHandler({ url: Api.check_user_group, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON }).then(response => {
      this.setState({
        isExitUserGroup: response?.is_user_group_exist,
        isFetchingUserGroup: false
      })
    }).catch(error => {
      this.setState({
        isFetchingUserGroup: false
      })
    })
  }

  fetchPendingVoucher = async (voucherId) => {
    try {
      this.setState({
        isFetchingData: true
      })
      let status;
      switch (this.props.status) {
        case 'isPending':
          status = 'PENDING'
          break;
        case 'isReady':
          status = 'READY'
          break;
        case 'isConfirm':
          status = 'CONFIRM'
          break;
        default:
          break;
      }
      const requestParams = {
        page: 0,
        status: status,
        uniqueId: voucherId
      }
      const response = await ApiHandler({ url: Api.all_vouchers, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams });
      if (response && response.content) {
        let updatedList = [];
        response.content && response.content.forEach(item => {
          const time = dayjs(item.createdDate, 'YYYY-MM-DD HH:mm:ss');
          if (time) {
            item = {
              ...item,
              createdDate: time.format('DD MMM YYYY hh:mm A')
            }
          }
          updatedList.push(item)
        })
        this.setState({
          data: updatedList,
          isFetchingData: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }


  loadMore = async () => {
    const { data, page } = this.state
    this.setState({
      isLoadMore: true
    })
    let status;
    switch (this.props.status) {
      case 'isPending':
        status = 'PENDING'
        break;
      case 'isReady':
        status = 'READY'
        break;
      case 'isConfirm':
        status = 'CONFIRM'
        break;
      default:
        break;
    }
    const requestParams = {
      page: page + 1,
      status
    }

    try {

      const response = await ApiHandler({ url: Api.all_vouchers, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
      if (response) {
        let updatedList = data
        let isLastData = false
        if (response.content) {
          response.content.forEach(noti => {

            const notiTime = dayjs(noti.createdDate, 'YYYY-MM-DD HH:mm:ss');
            if (notiTime) {
              noti = { ...noti, createdDate: notiTime.format('DD MMM YYYY hh:mm A') }
            }
            updatedList.push(noti)
          })
          if (response.content.length < 20) {
            isLastData = true
          }
        }
        this.setState({
          data: updatedList,
          isLastData: isLastData,
          page: page + 1
        })
      }
    } catch (error) {

    }
    this.setState({
      isLoadMore: false
    })
  }

  handleOpenModal = (voucherId) => {
    this.setState({ isOpenModal: true, selectedVoucherId: voucherId });
  };

  onSearch = (value) => {
    if (value) {
      this.fetchPendingVoucher(value);
      this.setState({
        isLastData: true
      })
    } else {
      this.fetchPendingVoucher();
    }
  }

  closeVoucherModal = () => {
    this.setState({ isOpenModal: false }, () => {
      this.fetchPendingVoucher()
      this.fetchCheckUserGroup()
    });
  }

  render() {
    const { data, isFetchingData, isLastData, isLoadMore, isOpenModal, selectedVoucherId, isExitUserGroup } = this.state;

    const isReady = data?.length > 0 && data[0]?.status === "READY";
    const titleStyles = isReady ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } : {}

    return (
      <>
        <Modal title={
          <div style={titleStyles}>
            <span style={{ fontSize: 20 }}>{i18next.t("confirm_order_detail")}</span>
            {
              data?.length > 0 && data[0]?.status === "READY" && isExitUserGroup && <SvgEditFilled width={20} height={20} color={Theme.colors.primary} style={{ cursor: 'pointer' }} onClick={() => this.props.navigate(CustomPath.confirm_order_update, { state: { uniqueId: selectedVoucherId } })} />
            }
          </div>
        } centered open={isOpenModal} onCancel={() => this.setState({ isOpenModal: false, selectedVoucherId: null })} footer={null} closable={data?.length > 0 && data[0]?.status === "READY" ? false : true}>
          {
            isOpenModal &&
            <VoucherDetailModal voucherId={selectedVoucherId} isExitUserGroup={isExitUserGroup} closeVoucherModal={this.closeVoucherModal} />
          }
        </Modal>
        <Row style={{ marginBottom: 20 }}>
          <Col span={8}>
            <NJVInput size="large" issearchinput="true" placeholder={i18next.t("search_voucher_id")} bgcolor="#f1f1f1" onSearch={this.onSearch} />
          </Col>
          <Col span={16} style={{ textAlign: 'right' }}>
            {
              this.props.status === 'isPending' ? (
                <Link to={CustomPath.confirm_order_create}>
                  <NJVButton
                    type="primary"
                    icon={<PlusCircleFilled />}
                  >
                    {i18next.t("create_voucher")}
                  </NJVButton>
                </Link>
              ) : <></>
            }
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {
              isFetchingData ?
                <Skeleton />
                :
                <>
                  {
                    data && data.length !== 0 ?
                      <>

                        <List itemLayout="vertical" size="large" dataSource={data} renderItem={(item) => (
                          <div style={{
                            paddingInline: 10,
                            paddingBlock: 12,
                            marginBottom: 10,
                            width: '100%',
                            fontWeight: 600,
                            background: '#f5f5f5',
                            borderRadius: 10,
                            cursor: 'pointer'
                          }}
                            onClick={() => this.handleOpenModal(item.uniqueId)}
                          >
                            <Row>
                              <Col lg={16} md={16} sm={24} xs={24} style={{
                                display: 'flex',
                                flexDirection: item.customerName || item.customerPhoneNo || item.facebookName ? 'column' : 'row'
                              }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {/* <SvgIdFilled width={15} height={15} color="#989898" /> */}
                                  {/* <span style={{ marginLeft: 10, fontSize: 16 }}>{item.uniqueId}</span> */}
                                  <span style={{ fontSize: 16 }}>{item.uniqueId}</span>
                                </div>
                                {
                                  item.facebookName && (
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#989898' }}>
                                      {/* <SvgFacebookFilled width={15} height={15} color="#989898" /> */}
                                      {/* <span>Facebook Name -</span> */}
                                      {/* <span style={{ marginLeft: 10 }}>{item.facebookName}</span> */}
                                      <span>{item.facebookName}</span>
                                    </div>
                                  )
                                }
                                {
                                  item.customerName && (
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#989898' }}>
                                      {/* <SvgNameFilled width={15} height={15} color="#989898" /> */}
                                      {/* <span>Customer Name -</span> */}
                                      {/* <span style={{ marginLeft: 10 }}>{item.customerName}</span> */}
                                      <span>{item.customerName}</span>
                                    </div>
                                  )
                                }
                                {
                                  item.customerPhoneNo && (
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#989898' }}>
                                      {/* <SvgPhoneOutlined width={15} height={15} color="#989898" /> */}
                                      {/* <span>Customer Phone Number -</span> */}
                                      {/* <span style={{ marginLeft: 10 }}>{item.customerPhoneNo}</span> */}
                                      <span>{item.customerPhoneNo}</span>
                                    </div>
                                  )
                                }{
                                  item.status === 'CONFIRM' && (
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#989898' }}>
                                      {/* <SvgTrackingFilled width={15} height={15} color={Theme.colors.primary} /> */}
                                      <span>{i18next.t("tracking_number")} -</span>
                                      {/* <span style={{ marginLeft: 10 }}>{item.orderTrackingNumber}</span> */}
                                      <span>{item.orderTrackingNumber}</span>
                                    </div>
                                  )
                                }
                              </Col>
                              <Col lg={8} md={8} sm={24} xs={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end', gap: 5 }}>
                                <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                                  <Tag color={`${item.orderType === 'Home Delivery' ? '#FFA500' : item.orderType === 'Bus Gate' ? '#809fff' : ''}`} style={{ marginRight: 0 }}>{item.orderType}</Tag>
                                </div>
                                <span style={{ fontSize: 12 }}> {item.createdDate}</span>
                              </Col>
                            </Row>
                          </div>
                        )
                        }
                        />
                        {
                          isLastData ?
                            <></>
                            :
                            <div style={{ textAlign: 'center' }}>
                              <NJVButton type="primary" onClick={() => this.loadMore()} loading={isLoadMore}>
                                Load More
                              </NJVButton>
                            </div>
                        }
                      </> :
                      <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                          <Lottie
                            style={{ height: '200px' }}
                            animationData={NoDataFoundAnimation}
                            loop={true}
                            autoplay={true}
                          />
                        </Col>
                        <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                          <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                            "No voucher list yet"
                          </div>
                        </Col>
                      </Row>
                  }
                </>
            }
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter)(VoucherListPage)
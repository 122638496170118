import { compose } from "@reduxjs/toolkit";
import { Breadcrumb, Card, Col, Form, Radio, Row, Select, Skeleton, Tag, message } from "antd";
import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { NJVButton, NJVInput, NJVSelect } from "../../components/core-component";
import Theme from "../../components/theme";
import { PhoneNumberValidator, RestrictPhoneNumberValidator } from "../../core/validator";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { Option } = Select

class UserAddressForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            division_collection: [],
            township_collection: [],
            is_my_location: false,
            is_default_location: false,
            isDataFetching: false,
            isTownShipFetching: false,
            is_saving_data: false,
            addressType: 1
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchDivision()
        this.setState({
            is_my_location: this.state.addressType === 1 ? false : true
        })
    }

    divisionSelectorChange = (value) => {
        this.fetchTownship(value)
        this.inputRef.current.setFieldsValue({ township: null })
    }
    fetchDivision = async () => {
        this.setState({
            isDataFetching: true
        })
        try {
            const response = await ApiHandler({url: Api.division_filter_active,method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON})
            if (response) {
                this.setState({
                    division_collection: response,
                    totalPagination: response.totalElements,
                    page: 0,
                });
            }

        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    fetchTownship = async (id) => {
        this.setState({
            isTownShipFetching: true
        })
        try {
            var requestParams = {
                page: 0,
                size: 100
            }
            const response = await ApiHandler({url: Api.township_filter_active, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams, specificId:id})
            if (response) {
                this.setState(prevState => ({
                    township_collection: response,
                    totalPagination: response.totalElements,
                    page: 0,
                }));
            }


        } catch (error) {
        }
        this.setState({
            isTownShipFetching: false
        })
    }

    setDefaultAddress = (event) => {
        this.setState({
            is_default_location: event.target.checked
        })

    }
    setMyAddress = (event) => {
        const addressTypeValue = parseInt(event.target.value);

        this.setState({
            is_my_location: addressTypeValue === 2 ? true : false,
            is_default_location: event.target.checked ? this.state.is_default_location : false,
            addressType: addressTypeValue,
        })
    }

    saveUserAddress = async (value) => {
        const { is_default_location, is_my_location } = this.state
        this.setState({
            is_saving_data: true
        })
        try {
            const response = await ApiHandler({url: Api.user_address, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON,
                requestData: {
                    "name": value.name,
                    "phone": value.phone,
                    "address1": value.address1,
                    "township": {
                        "id": value.township
                    },
                    "defaultLocation": is_default_location,
                    "myLocation": is_my_location
                }}
            )
            if (response.status === 200) {
                this.props.navigate(CustomPath.user_address)
            }
        } catch (error) {
            if (error && error.response.data.code === "044") {
                message.error(error.response.data.message)
                this.setState({
                    isCreatingParcel: false
                });
            }
        }
        this.setState({
            is_saving_data: false
        })
    }

    render() {
        const { division_collection, township_collection, isDataFetching, isTownShipFetching, is_saving_data, addressType } = this.state
        return (
            <Row>
                <Col lg={6} md={6} sm={1} xs={1} />
                <Col lg={12} md={12} sm={22} xs={22} >
                    <Row>
                        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Breadcrumb
                                items={[
                                    {
                                        title: 'Home',
                                    },
                                    {
                                        title: <Link to={CustomPath.user_address}>{i18next.t("fav_location")}</Link>,
                                    }
                                ]}
                            />
                        </Col>
                        <Col span={24}>
                            {
                                isDataFetching ?
                                    <Skeleton active />
                                    :
                                    <Card
                                        title="Form"
                                        style={{ marginTop: 15,backgroundColor: Theme.colors.secondary }}
                                        extra={<NJVButton type="primary" onClick={() => this.props.navigate(CustomPath.user_address)}>{i18next.t('back')}</NJVButton>
                                    }
                                    >
                                        <Form
                                            ref={this.inputRef}
                                            layout="vertical"
                                            onFinish={this.saveUserAddress}
                                        >
                                            <Form.Item className="location" name="name" label={i18next.t("name")} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                                <NJVInput size="large" />
                                            </Form.Item>
                                            <Form.Item
                                                className="location"
                                                name="phone"
                                                label={i18next.t("phone_number")}
                                                size={'large'}
                                                rules={[{ validator: RestrictPhoneNumberValidator }, { required: true }]}>
                                                <NJVInput size="large" placeholder={"0979xxxxxxx"} />
                                            </Form.Item>

                                            <Form.Item name="division" label={i18next.t("division_state")} rules={[{ required: true, message: '*(requried)' }]}>
                                                <NJVSelect
                                                    className="my-location-selector"
                                                    placeholder={i18next.t("choose_division")}
                                                    onChange={this.divisionSelectorChange}
                                                    size="large"
                                                >
                                                    {
                                                        division_collection && division_collection.map((division) => {
                                                            return <Option value={division.id}>{division.name}</Option>
                                                        })
                                                    }
                                                </NJVSelect>
                                            </Form.Item>
                                            <Form.Item name="township" label={i18next.t("township")} rules={[{ required: true, message: '*(requried)' }]}>
                                                <NJVSelect
                                                    className="my-location-selector"
                                                    placeholder={i18next.t("choose_township")}
                                                    onChange={this.townshipSelectorChange}
                                                    size="large"
                                                    loading={isTownShipFetching}

                                                >
                                                    {
                                                        township_collection && township_collection.map((township) => {
                                                            return <Option value={township.id}>{township.name} <Tag color="green">{township.postalCode}</Tag></Option>
                                                        })
                                                    }
                                                </NJVSelect>
                                            </Form.Item>
                                            <Form.Item className="location" name="address1" label={i18next.t("address")} rules={[{ required: true, message: '*(requried)' }]}>
                                                <NJVInput showCount maxLength={180}
                                                    style={{ height: 60 }} placeholder={i18next.t("enter_address")} istextareainput="true" />
                                            </Form.Item>
                                            <Form.Item label={i18next.t("location_type")}>
                                                <Radio.Group onChange={this.setMyAddress} value={addressType}>
                                                    <Radio value={1}>{i18next.t("loyal_customer")}</Radio>
                                                    <Radio value={2}>{i18next.t("my_address")}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                            <Form.Item>
                                                <NJVButton type="primary" htmlType="submit" loading={is_saving_data}>{i18next.t("save")}</NJVButton>
                                            </Form.Item>
                                        </Form>
                                    </Card>
                            }
                        </Col>
                    </Row>
                </Col >
            </Row >

        )
    }
}
export default compose(withRouter)(UserAddressForm);
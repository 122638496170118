import { PlusOutlined } from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Col, Image, Row, Upload } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import { NJVButton } from '../../components/core-component'
import { SvgArrowLeftFilled } from "../../components/custom-svgIcon"
import { TitleLevel3 } from '../../components/general-component'
import Theme from '../../components/theme'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import { updateSettlementNoti } from '../../redux/profile-slice'
import CustomPath from '../../routes/custom-path'

class PaymentInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.location?.state || null,
            isSendingScreenshot: false,
            previewOpen: false,
            previewImage: '',
            fileList: [],
        }
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
        });
    };

    handleChange = ({ fileList }) => {
        this.setState({ fileList });
    };

    handleImageSubmit = async (imageFile, shipperId) => {
        this.setState({
            isSendingScreenshot: true,
        })
        try {
            const file = imageFile[0];
            const formData = new FormData()
            formData.append('file', file.originFileObj)
            formData.append('owedShipperId', shipperId)
            let response = await ApiHandler({ url: Api.shipper_settlement_screenshot, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            if (response.status === 200) {
                this.setState({
                    isPending: false,
                    isSendingScreenshot: false,
                })
                this.handleBackButton()
            }
        } catch (error) {
            console.log(error);
        }

    }

    handleBackButton = () => {
        this.props.navigate(CustomPath.invoice)
        this.props.dispatch(updateSettlementNoti(true))
    }

    render() {
        const { fileList, previewOpen, previewImage, data, isSendingScreenshot } = this.state

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        const buttonStyle = {
            marginTop: 20,
            backgroundColor: fileList.length === 0 ? '#f5f5f5' : `${Theme.colors.primary}`,
            color: fileList.length === 0 ? '#ccc' : '#fff'
        };

        return (
            <>
                {/* <Modal title={null} centered width={350}
                    onOk={() => this.setState({ isSubmittedModalOpen: false })} open={isSubmittedModalOpen} footer={null} onCancel={() => this.setState({ isSubmittedModalOpen: false })}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: 16 }}>
                        <SvgCheck width={50} height={50} color="#00BA00" />
                        <p style={{ fontWeight: 700, fontSize: 20 }}>Success</p>
                        <div style={{ fontWeight: 500 }}>Your payment screenshot has been submitted successfully.</div>
                    </div>
                </Modal> */}
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 40 }}>
                            <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                <NJVButton shape="circle" icon={<SvgArrowLeftFilled width={20} height={20} />} style={{ marginRight: 20 }} onClick={this.handleBackButton} />
                                <TitleLevel3 label={`${data?.name} Payment Detail`} />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <div style={data?.style ? { ...data.style, marginRight: '20px' } : {}}>
                                    {!data?.quickPay && <Image width={80} src={data?.photo} preview={false} style={{ cursor: 'pointer' }} />}
                                </div>
                            </Col>
                            {
                                data?.quickPay ?
                                    <Row>
                                        <Col lg={8} md={8} sm={24} xs={24}>
                                            <Image src={data?.account?.acc1} preview={false} />
                                        </Col>
                                        <Col lg={8} md={8} sm={24} xs={24}>
                                            <Image src={data?.account?.acc2} preview={false} />
                                        </Col>
                                        <Col lg={8} md={8} sm={24} xs={24}>
                                            <Image src={data?.account?.acc3} preview={false} />
                                        </Col>
                                    </Row>
                                    : (
                                        <Col span={24} style={{ display: 'flex', gap: 4 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600, color: Theme.colors.primary }}>
                                                <p>Account Owner</p>
                                                <p>Account Number</p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: 3, fontWeight: 600 }}>
                                                <p>- FLYING HELIOS CO., LTD</p>
                                                {data?.account?.acc1 && <p>- {data?.account?.acc1}</p>}
                                                {data?.account?.acc2 && <p>- {data?.account?.acc2}</p>}
                                            </div>
                                        </Col>
                                    )
                            }
                            <Col span={24} style={{ textAlign: data?.quickPay ? 'center' : '' }}>
                                <span style={{ fontWeight: 600, color: Theme.colors.primary, paddingInline: 0 }}>{i18next.t("owed_msg")}</span>
                            </Col>
                            <Col span={data?.quickPay && 24} style={{ textAlign: 'center', marginLeft: data?.quickPay ? 0 : 120, display: data?.quickPay ? 'flex' : 'block', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleChange}
                                    maxCount={1}
                                    accept={["image/png, image/jpeg"]}
                                    beforeUpload={() => false}
                                >
                                    {fileList.length === 1 ? null : uploadButton}
                                </Upload>
                                {previewImage && (
                                    <Image
                                        wrapperStyle={{ display: 'none' }}
                                        preview={{
                                            visible: previewOpen,
                                            onVisibleChange: (visible) => this.setState({ previewOpen: visible }),
                                            afterOpenChange: (visible) => !visible && this.setState({ previewImage: '' }),
                                        }}
                                        src={previewImage}
                                    />
                                )}
                                <NJVButton loading={isSendingScreenshot} type="primary" disabled={fileList.length === 0} size="large" style={buttonStyle} onClick={() => this.handleImageSubmit(fileList, data?.id)}>{i18next.t("submit")}</NJVButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(PaymentInfo)
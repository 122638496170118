import { compose } from '@reduxjs/toolkit'
import { Carousel, Col, ConfigProvider, Divider, Image, Row, Skeleton, Tag, Timeline, message } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json'
import Cancel from '../../animate-lottie/package-cancel.json'
import Delivered from '../../animate-lottie/package-delivered.json'
import OnVehicle from '../../animate-lottie/package-on-vehicle-for-delivery.json'
import Pending from '../../animate-lottie/package-pending.json'
import Pickup from '../../animate-lottie/package-pick-up.json'
import Processing from '../../animate-lottie/package-processing.json'
import Return from '../../animate-lottie/package-return.json'
import ThumbnailImg from '../../asset/ninja_thumbnail.jpg'
import { NJVButton, NJVInput, NJVSpacer } from '../../components/core-component'
import { SvgMailBoxFilled, SvgOrderDollyFilled, SvgPackLocationFilled, SvgSearchOutlined, SvgTruckFilled } from "../../components/custom-svgIcon"
import Theme from '../../components/theme'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandlerNoAuth } from '../../network/network-manager'
import withRouter from '../../network/with-router'

import i18next from 'i18next'
import Lottie from 'lottie-react'
import { logClick, logView } from '../../firebase/analyticsUtils'

const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 15, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)

}

const NJVValue = ({ value }) => {
    return (
        <>
            <div style={{ fontSize: 17, fontWeight: '500', color: 'black' }}>{value}</div>
        </>)
}

const empty_product_image = require('../../asset/no-data-found.png')

const iconCircleStyle = { display: 'flex', justifyContent: 'center', borderRadius: '50%', background: '#f5f5f5', padding: 10, width: 40, height: 40, alignItems: 'center' }
const iconTextStyle = { display: 'flex', flexDirection: 'column', fontSize: 14, fontWeight: '600', marginLeft: 10, color: 'gray', gap: 5 }

const PENDING_PICK_UP = 'PENDING_PICK_UP'

const TimelineStep = ({ orderStatus, event }) => {
    return (
        <>
            {
                <div style={{ width: '100%' }}>
                    <div style={{ fontWeight: 500 }}>{orderStatus}</div>
                    {
                        event.deliveryException ?
                            <>
                                {
                                    event.deliveryException.failure_reason ?
                                        <div style={{
                                            width: 'fit-content',
                                            paddingBlock: 7, paddingInline: 10, borderRadius: 8, background: '#FF2400', color: 'white', marginTop: 6
                                        }}>{event.deliveryException.failure_reason}</div>
                                        :
                                        <></>
                                }


                                {
                                    event.deliveryException.proof && event.deliveryException.proof.image_uris ?
                                        <div style={{ display: 'flex' }}>
                                            {
                                                event.deliveryException.proof.image_uris.map(image =>
                                                    <div style={{ marginLeft: 5, marginTop: 5 }}>
                                                        <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                            :
                            <></>
                    }
                    {
                        event.deliveryInformation ?
                            <>
                                {
                                    event.deliveryInformation.proof && event.deliveryInformation.proof.image_uris ?
                                        <div style={{ display: 'flex' }}>
                                            {
                                                event.deliveryInformation.proof.image_uris.map(image =>
                                                    <div style={{ marginLeft: 5, marginTop: 5 }}>
                                                        <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }
                    {
                        event.pickupException ?
                            <>
                                {
                                    event.pickupException.failure_reason ?
                                        <div style={{
                                            width: 'fit-content',
                                            paddingBlock: 7, paddingInline: 10, borderRadius: 8, background: 'red', color: 'white', marginTop: 6
                                        }}>{event.pickupException.failure_reason}</div>

                                        :
                                        <></>
                                }
                            </>

                            :
                            <></>
                    }
                    {
                        event.pickedUpInformation ?
                            <>
                                {
                                    event.pickedUpInformation.proof && event.pickedUpInformation.proof.image_uris ?
                                        <div style={{ display: 'flex' }}>
                                            {
                                                event.pickedUpInformation.proof.image_uris.map(image =>
                                                    <div style={{ marginLeft: 5, marginTop: 5 }}>
                                                        <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        :
                                        <></>
                                }

                            </>
                            :
                            <></>
                    }

                </div >
            }
        </>

    )
}

const getColor = (orderStatus) => {
    switch (orderStatus) {
        case 'PENDING_PICK_UP':
            return '#FFA500'
        case 'PICKED_UP':
            return '#0000FF'
        case 'PROCESSING':
            return '#FFFF00'
        case 'DELIVERED':
            return '#008000'
        case 'CANCELLED':
            return '#FF0000'
        case 'PICK_UP_EXCEPTION':
            return '#FF0000'
        case 'DELIVERY_EXCEPTION':
            return '#FF0000'
        default:
            return '#808080'
    }
}

const setAnimation = (orderStatus) => {
    switch (orderStatus) {
        case 'PENDING_PICK_UP':
            return Pending
        case 'PICKED_UP':
            return Pickup
        case 'ARRIVED_AT_ORIGIN_HUB':
        case 'ARRIVED_AT_SORTING_HUB':
        case 'ARRIVED_AT_TRANSIT_HUB':
        case 'ARRIVED_AT_DESTINATION_HUB':
        case 'IN_TRANSIT_TO_NEXT_SORTING_HUB':
            return Processing
        case 'DELIVERED':
            return Delivered
        case 'CANCELLED':
        case 'DELIVERY_EXCEPTION':
        case 'PICK_UP_EXCEPTION':
            return Cancel
        case 'ON_VEHICLE_FOR_DELIVERY':
            return OnVehicle
        case 'RETURNED_TO_SENDER':
            return Return
        default:
            return Pending
    }
}

class PublicOrderDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter_name: '',
            isFetchingData: false,
            isSearchTracking: false,
            data: null,
            isFetchingImageData: false,
            isFetchingEvent: false,
            orderSteps: [],
            imageItems: [],
        }
    }

    componentDidMount = () => {
        const currentUrl = window.location.href;
        const index = currentUrl.indexOf('id=');
        let substring = null;
        if (index !== -1) {
            substring = currentUrl.substring(index + 'id='.length);
            this.setState({ filter_name: substring })
            this.fetchData(substring)
        }
        this.fetchImageData()
        this.fetchOrderEvent(substring)
        logView('public_tracking_screen')
    }

    fetchImageData = async () => {
        this.setState({
            isFetchingImageData: true
        })
        const requestParams = {
            'bannerType': 'LOYALTY_SHOP_DASHBOARD'
        }
        await ApiHandlerNoAuth({ url: Api.banner_slide, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    isFetchingImageData: false
                })
                response.forEach((item, index) => {
                    this.setState((prevState) => ({
                        imageItems: [...prevState.imageItems, item]
                    }
                    ))
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isFetchingImageData: false
                })
            })
    }

    handleFilter = (event) => {
        this.setState({ filter_name: event.target.value })
    }

    search = () => {
        const { filter_name } = this.state
        if (!filter_name || filter_name.length < 10) {
            message.destroy();
            message.error("Please enter tracking number first.")
            return
        }
        const filterId = filter_name.trim()
        const currentUrl = window.location.href;
        const index = currentUrl.indexOf('tracking')
        if (index === -1) {
            message.error("Invalid URL format.");
            return;
        }
        const newUrl = `${currentUrl.substring(0, index + 'tracking'.length)}?id=${filterId}`
        window.history.pushState({ path: newUrl }, '', newUrl)
        this.fetchData(filterId)
        this.fetchOrderEvent(filterId)
    }

    fetchData = async (trackingId) => {
        const requestParams = {
            trackingNumber: trackingId
        }
        this.setState({
            isFetchingData: true,
            isSearchTracking: true
        })
        try {
            const response = await ApiHandlerNoAuth({ url: Api.public_order_get_by_tracking_number, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let data = response;
                let fromAddress = data.fromAddress
                let toAddress = data.toAddress
                if (data && data.fromTownship) {
                    fromAddress = fromAddress + "," + data.fromTownship.name
                    if (data.fromTownship.division) {
                        fromAddress = fromAddress + "," + data.fromTownship.division.name
                    }
                }
                if (data && data.toTownship) {
                    toAddress = toAddress + "," + data.toTownship.name
                    if (data.toTownship.division) {
                        toAddress = toAddress + "," + data.toTownship.division.name
                    }
                }
                data = { ...data, senderAddress: fromAddress, recipientAddress: toAddress }

                if (data.createdDate != null) {
                    const createdDate = dayjs(data.createdDate, 'YYYY-MM-DD HH:mm A');
                    if (createdDate) {
                        data = { ...data, 'createdDate': createdDate.format('YYYY-MMM-DD hh:mm A') }
                    }
                }

                this.setState({
                    data: data,
                    isFetchingData: false,
                })
            }
        } catch (error) {
            console.log(error);
            if (error.response.data.code === '001') {
                this.setState({
                    data: null,
                    isFetchingData: false
                })
            }
        }
    }

    fetchOrderEvent = async (trackingId) => {
        this.setState({
            isFetchingEvent: true
        })
        await ApiHandlerNoAuth({ url: Api.public_order_event, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: { trackingNumber: trackingId } })
            .then((response) => {
                let status = PENDING_PICK_UP
                let timeMap = {}
                let steps = []
                let isFoundProcessingStatus = false

                response?.forEach((orderEvent, index) => {
                    status = orderEvent.orderStatus
                    let formatedEventTime = ''
                    if (orderEvent.timestamp) {
                        const eventTime = dayjs(orderEvent.timestamp, 'YYYY-MM-DD HH:mm:ss');
                        if (eventTime) {
                            timeMap[orderEvent.orderStatus] = eventTime.format('YYYY-MMM-DD hh:mm A')
                            formatedEventTime = eventTime.format('MMM DD, YYYY hh:mm A')
                        }
                    }
                    if (status === 'ARRIVED_AT_ORIGIN_HUB' || status === 'ARRIVED_AT_SORTING_HUB' || status === 'ARRIVED_AT_TRANSIT_HUB' || status === 'ARRIVED_AT_DESTINATION_HUB' || status === 'IN_TRANSIT_TO_NEXT_SORTING_HUB' || status === 'AT_PUDO') {
                        if (!isFoundProcessingStatus) {
                            orderEvent = { ...orderEvent, orderStatus: 'PROCESSING', status: 'Processing' }
                            steps.push(
                                {
                                    key: index + 1,
                                    color: getColor(status),
                                    label: <div style={{ fontWeight: 500, marginRight: 10 }}>{formatedEventTime}</div>,
                                    children: (<TimelineStep orderStatus={orderEvent.status} event={orderEvent} />)
                                }
                            )
                        }
                        isFoundProcessingStatus = true
                    } else {
                        steps.push(
                            {
                                key: index + 1,
                                color: getColor(status),
                                label: <div style={{ fontWeight: 500, marginRight: 10 }}>{formatedEventTime}</div>,
                                children: (<TimelineStep orderStatus={orderEvent.status} event={orderEvent} />)
                            }
                        )
                    }
                })

                this.setState({
                    isFetchingEvent: false,

                    orderSteps: steps
                })
            }).catch((error) => { })
    }

    render() {
        const { data, isFetchingData, orderSteps, isSearchTracking, imageItems, isFetchingImageData, filter_name, isFetchingEvent } = this.state;

        return (
            <Row style={{ marginTop: 30 }}>
                <Col lg={4} md={3} sm={1} xs={1}></Col>
                <Col lg={16} md={18} sm={22} xs={22}>
                    <Row gutter={16}>
                        <Col lg={20} md={20} sm={18} xs={18}>
                            <NJVInput
                                size="large"
                                value={filter_name && filter_name}
                                bgcolor='#f5f5f5'
                                placeholder="Enter parcel tracking id" onChange={this.handleFilter} />
                        </Col>
                        <Col lg={4} md={4} sm={0} xs={0}>
                            <NJVButton size="large" shape="square" type="primary" style={{ backgroundColor: Theme.colors.primary, width: '100%' }} onClick={() => this.search()}>{i18next.t("search")}</NJVButton>
                        </Col>
                        <Col lg={0} md={0} sm={6} xs={6}>
                            <NJVButton size="large" shape="square" type="primary" style={{ backgroundColor: Theme.colors.primary, width: '100%' }} onClick={() => this.search()} icon={<SvgSearchOutlined width={18} height={18} color="#fff" style={{ marginTop: 4 }} />} />
                        </Col>
                    </Row>
                    <Row style={{ marginBlock: 30 }}>
                        {
                            isFetchingData ?
                                <Skeleton />
                                :
                                <>
                                    {
                                        isSearchTracking && data ?
                                            <Row gutter={[24, 24]}>
                                                <Col span={24}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Lottie
                                                            style={{ height: '130px' }}
                                                            animationData={setAnimation(data.orderStatus)}
                                                            loop={true}
                                                            autoplay={true}
                                                        />
                                                        <Tag color={getColor(data.orderStatus)} style={{ paddingInline: 10, paddingBlock: 5, borderRadius: 30, fontSize: 14, marginTop: 20 }}>
                                                            {data.orderStatus === 'ARRIVED_AT_ORIGIN_HUB' ||
                                                                data.orderStatus === 'ARRIVED_AT_SORTING_HUB' ||
                                                                data.orderStatus === 'ARRIVED_AT_TRANSIT_HUB' ||
                                                                data.orderStatus === 'IN_TRANSIT_TO_NEXT_SORTING_HUB' ? data.ninjaCoreOrderStatus = 'Processing' : data.ninjaCoreOrderStatus
                                                            }
                                                        </Tag>
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <Row gutter={[16, 16]}>
                                                        <Col lg={12} md={12} sm={24} xs={24}>
                                                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 16, fontWeight: 600, paddingLeft: 2, paddingRight: 20 }}>
                                                                <div style={{ ...iconCircleStyle, marginRight: 10 }}>
                                                                    {
                                                                        data.orderType === 'Home Delivery' ? <SvgOrderDollyFilled width={18} height={18} color={Theme.colors.primary} /> : data.orderType === 'Bus_Gate' ? <SvgTruckFilled width={18} height={18} color={Theme.colors.primary} /> : data.orderType === 'Bus Gate' ? <SvgTruckFilled width={18} height={18} color={Theme.colors.primary} /> : data.orderType === 'Post Office' ? <SvgMailBoxFilled width={18} height={20} color={Theme.colors.primary} /> : <SvgOrderDollyFilled width={18} height={18} color={Theme.colors.primary} />
                                                                    }
                                                                </div>
                                                                <span>{data.orderType}</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={12} md={12} sm={0} xs={0} style={{ textAlign: 'right' }}>
                                                            <NJVValue value={data.trackingNumber} />
                                                        </Col>
                                                        <Col lg={0} md={0} sm={24} xs={24}>
                                                            <NJVValue value={data.trackingNumber} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                                <div style={iconCircleStyle}>
                                                                    <SvgPackLocationFilled width={18} height={18} color={Theme.colors.primary} />
                                                                </div>
                                                                <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{data.senderName}</span>
                                                            </div>
                                                            <div style={{ display: 'flex', margin: '2px 10px 3px 10px' }}>
                                                                <div style={{ width: 20, display: 'flex', justifyContent: 'center' }}>
                                                                    <div style={{
                                                                        borderLeft: `3px dotted gray`,
                                                                        height: 60,
                                                                        margin: ' 0 auto'
                                                                    }}></div>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', gap: 10 }}>
                                                                <div style={iconCircleStyle}>
                                                                    <SvgPackLocationFilled width={18} height={18} color={Theme.colors.primary} />
                                                                </div>
                                                                <div style={iconTextStyle}>
                                                                    <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{data.recipientName}</span>
                                                                    <div>
                                                                        <NJVLabel label={data.recipientPhoneNumber} />
                                                                        {
                                                                            data.toTownship && data.toAddress ? <NJVLabel label={`${data.toAddress}, ${data.toTownship.name}, ${data.toTownship.division.name}`} /> : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {
                                                    imageItems &&
                                                    <>
                                                        <Col span={24}>
                                                            <Divider style={{ margin: 0 }} />
                                                        </Col>
                                                        <Col span={24}
                                                        >
                                                            {isFetchingImageData ? <Skeleton active /> :
                                                                <Carousel
                                                                    style={{ textAlign: 'center' }}
                                                                    draggable={true}
                                                                    arrows
                                                                    autoplay
                                                                    // autoplaySpeed={2000}
                                                                    infinite
                                                                    >
                                                                    {
                                                                        imageItems?.map((item) => (
                                                                            <div key={item.id}
                                                                            //  style={{ height: 200 }}
                                                                            >
                                                                                <a
                                                                                    href={item.link && item.link !== '' ? item.link : ''}
                                                                                    target={item.link && item.link !== '' ? '_blank' : '_self'}
                                                                                    onClick={() => logClick('public_tracking_screen', item.name)}
                                                                                >
                                                                                    <Image
                                                                                        alt={item.name}
                                                                                        style={{
                                                                                            width: '100%',
                                                                                            height: 200,
                                                                                            borderRadius: 10,
                                                                                            objectFit: 'cover',
                                                                                            cursor: item.link && item.link !== '' ? 'pointer' : 'default'
                                                                                        }}
                                                                                        src={item.photoUrl}
                                                                                        preview={false}
                                                                                        fallback={ThumbnailImg}
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        ))}
                                                                </Carousel>
                                                            }
                                                        </Col>
                                                    </>
                                                }
                                                <Col span={24}>
                                                    <Divider style={{ margin: 0 }} />
                                                </Col>
                                                <Col span={24}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                        <div>
                                                            <NJVLabel label={i18next.t('payment_option')} />
                                                            <NJVValue value={`${data.paymentOption}`} />
                                                        </div>
                                                        <div>
                                                            <NJVLabel label={i18next.t('cod')} />
                                                            <NJVValue value={`${data.codAmount ? data.codAmount : 0} MMK`} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <Divider style={{ margin: 0 }} />
                                                </Col>
                                                <Col span={24}>
                                                    {
                                                        isFetchingEvent ? <Skeleton /> : <>
                                                            {
                                                                orderSteps && orderSteps.length !== 0 ?
                                                                    <>
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <NJVLabel label="Parcel Events" />
                                                                        </div>
                                                                        <NJVSpacer height={10} />
                                                                        <ConfigProvider theme={{
                                                                            token: {
                                                                                fontWeight: 500
                                                                            },
                                                                            components: {
                                                                                Timeline: {
                                                                                    itemPaddingBottom: 40
                                                                                },
                                                                            },
                                                                        }}>
                                                                            <Timeline
                                                                                mode='left'
                                                                                items={orderSteps}
                                                                            />
                                                                        </ConfigProvider>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                            :
                                            isSearchTracking && data === null ?
                                                <Col span={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 60, alignItems: 'center' }}>
                                                    <Image
                                                        width={130}
                                                        preview={false}
                                                        src={empty_product_image} />
                                                    <span style={{ marginTop: 20, fontSize: 18 }}>No Parcel found</span>
                                                </Col>
                                                :
                                                <Col span={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 60, alignItems: 'center' }}>
                                                    <Lottie
                                                        style={{ height: '200px' }}
                                                        animationData={NoDataFoundAnimation}
                                                        loop={true}
                                                        autoplay={true}
                                                    />
                                                    <span style={{ marginTop: 20, fontSize: 16 }}>Start tracking your parcel by entering your tracking ID in the box above.</span>
                                                </Col>
                                    }
                                </>
                        }
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(PublicOrderDetail)
